<template>
  <div class="options">
    <div
      v-for="option in options"
      class="option"
      :class="{ selected: modelValue === option.value }"
      @click="select(option)"
      :data-test-id="option.value"
    >
      <div class="wrapper">
        <p>{{ t(option.label) }}</p>
        <p v-if="option.subtitle" class="subtitle">{{ t(option.subtitle) }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

type Option = { value: string | boolean; label: string; subtitle?: string }

const props = defineProps<{
  modelValue: Nullable<string | boolean>
  options: Option[]
  trackSelectEvent?: AnalyticsEvent
}>()

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { track } = useAnalytics()

function select(option: Option) {
  emit('update:modelValue', option.value)
  if (props.trackSelectEvent) {
    track(props.trackSelectEvent)
  }
}
</script>
<style lang="sass" scoped>
.options
  display: flex
  gap: 0.5rem

  .option
    p
      margin: 0

    .subtitle
      font-size: 0.875rem
      color: #9F9F9F

    .wrapper
      box-sizing: border-box
      font-size: 0.875rem
      padding: 0.5rem 1rem
      border-radius: 0.5rem
      border: 1px solid #d4d4d4
      min-height: 5rem
      display: flex
      flex-direction: column
      justify-content: center

      p
        line-height: 1.5rem

    &.selected
      .wrapper
        cursor: default
        background-color: #F6F3A0

      .subtitle
        color: #0B0B0B

    &:not(.selected)
      .wrapper:hover
        cursor: pointer
        border-color: #0B0B0B
</style>
