<template>
  <div>
    <div v-if="modelValue">
      <label>{{ t('common.catastralReference') }}</label>
      <div :class="{ disabled }" class="flex gap-1 applied">
        <p>{{ modelValue }}</p>
        <Button variant="failure" :disabled="disabled" :loading="loading" @click="deleteCatastralReference">
          {{ t('common.delete') }}
        </Button>
      </div>
    </div>
    <div v-else class="flex align-end gap-1 wrap">
      <InputField
        v-model="catastralSearch"
        :label="t('common.catastralReference')"
        :placeholder="t('property.core.catastralReferencePlaceholder')"
        :disabled="disabled"
      />
      <Button
        variant="secondary"
        :disabled="disabled || !catastralSearch"
        :loading="loading"
        @click="addCatastralReference"
      >
        {{ t('property.core.addReference') }}
      </Button>
    </div>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
    <p class="light">{{ t('property.core.catastralReferenceInformation') }}</p>
  </div>
</template>
<script lang="ts" setup>
import { Button, InputField } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import type { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCatastralInformationByReference } from '@/modules/property/application'
import type { CatastralAddress } from '@/modules/property/domain/catastralAddress'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: Nullable<string>
  disabled?: boolean
  trackEnterEvent?: AnalyticsEvent
  trackClearEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Nullable<string>): void
  (e: 'address', value: CatastralAddress): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const catastralSearch = ref('')
const error = ref('')
const loading = ref(false)

async function addCatastralReference() {
  if (!catastralSearch.value) return

  loading.value = true
  error.value = ''
  try {
    const address = await findCatastralInformationByReference(catastralSearch.value)
    emit('update:modelValue', catastralSearch.value)
    emit('address', address)
    if (props.trackEnterEvent) {
      track(props.trackEnterEvent)
    }
  } catch {
    emit('update:modelValue', null)
    error.value = 'property.core.catastralReferenceError'
  }
  loading.value = false
}

function deleteCatastralReference() {
  emit('update:modelValue', null)
  if (props.trackClearEvent) {
    track(props.trackClearEvent)
  }
}
</script>
<style lang="sass" scoped>
label
  display: block
  margin-bottom: 0.5rem
  font-weight: 400
  font-size: 0.875rem

.applied
  p
    flex-grow: 1
    box-sizing: border-box
    padding: 0.7rem 1rem
    margin: 0
    border-radius: 0.5rem
    border: 1px solid #d4d4d4
    display: flex
    flex-direction: column
    justify-content: center
    background-color: #F6F3A0

  &.disabled
    opacity: 0.4

button.button
  flex-shrink: 0
  padding: 12px 30px
</style>
