<template>
  <div class="loader">
    <img src="@/assets/images/vivara_logo_dark.svg" />
  </div>
</template>
<style scoped lang="sass">
@keyframes pulse
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0

.loader
  position: fixed
  z-index: 100
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: white
  display: flex
  align-items: center
  justify-content: center

  img
    animation-name: pulse
    animation-timing-function: linear
    animation-iteration-count: infinite
    animation-duration: 2s
</style>
