export const enMessages = {
  application: {
    details: {
      description:
        'Here you can consult the documentation you have sent for the property application. This section is read only. If you wish to make changes, please contact us.',
      documents: {
        title: 'Required documentation'
      },
      tenant: {
        coTenant: 'Co-tenant',
        email: 'E-mail',
        employmentStatus: 'Employment status',
        phone: 'Phone number',
        mainTenant: 'Primary tenant',
        name: 'Name',
        surname: 'Surname'
      },
      title: 'Documentation Sent'
    },
    documentation: {
      back: 'Request property',
      description:
        'To continue with the process, we need you to upload the documentation of all the tenants who will be living in the property. Once you have sent all the documents, we will review them to validate their solvency. We will contact you by phone to let you know if everything is correct.',
      pending: 'Pending',
      documents: 'Documents',
      title: 'Required Tenant Documentation'
    },
    documentationCompleted: {
      bold: "You have successfully submitted your application for one of Vivara's properties.",
      finish: 'Take me to my application page',
      main: 'We will now review the information and inform you with the next steps.'
    },
    requiredDocumentation: 'Required Documentation',
    saveAndExit: 'Save and exit',
    send: 'Send'
  },
  applications: {
    accountSuccess: {
      title: 'Your Vivara account has been successfully created.',
      description:
        'You have applied for one of our properties through Idealista. To complete the process, upload the necessary documentation to verify the financial solvency and tenant debt status of all tenants.',
      question: 'Would you like to continue with this process now?'
    },
    accountSuccessEmpty: {
      main: 'Your Vivara account has been successfully created.',
      bold: 'You will soon receive a confirmation email with more details.'
    },
    applied: 'Applied',
    completeDocumentation: 'Complete documentation',
    completeLater: 'Complete later',
    empty: {
      title: 'You currently have no active applications for Vivara properties.',
      description: 'You can explore the available properties in the “Vivara Properties” section to apply for a visit.'
    },
    myApplications: 'My Applications',
    pendingToSend: 'Pending to send',
    request: 'Continue application'
  },
  auth: {
    logOut: 'Log out',
    privacyPolicy: 'Privacy Policy',
    signIn: 'Sign in to your account',
    signInWithGoogle: 'Continue with Google',
    signInWithFacebook: 'Continue with Facebook',
    signUp: 'Sign up',
    signUpPrompt: 'Don’t have an account?',
    signUpLink: 'Create an account',
    termsAndConditions: 'Terms and Conditions',
    welcome: 'Welcome to Vivara'
  },
  authenticationError: {
    invalidCredentials: 'The email or password is incorrect',
    userAlreadyExists: 'The email is already in use'
  },
  common: {
    accept: 'Accept',
    address: 'Address',
    and: 'and',
    cancel: 'Cancel',
    catastralReference: 'Catastral reference',
    congratulations: 'Congratulations!',
    continue: 'Continue',
    delete: 'Delete',
    floor: 'Floor',
    month: 'month',
    or: 'or',
    property: {
      availability: {
        exactDate: 'I know the exact date',
        less1month: 'Less than 1 month',
        oneTo3months: 'One to 3 months',
        more3months: 'More than 3 months'
      },
      bathrooms: 'Bathrooms',
      bedrooms: 'Bedrooms',
      type: {
        flat: 'Flat',
        penthouse: 'Penthouse',
        duplex: 'Duplex',
        attachedHouse: 'Attached House',
        detachedHouse: 'Detached House'
      },
      listed: 'Listed',
      indoorExtras: {
        fridge: 'Fridge',
        oven: 'Oven',
        microwave: 'Microwave',
        stove: 'Stove',
        dishwasher: 'Dishwasher',
        washingMachine: 'Washing machine',
        dryer: 'Dryer',
        alarm: 'Alarm',
        airConditioning: 'Air conditioning'
      },
      newProperty: 'New property',
      outdoorExtras: {
        lift: 'Lift',
        terrace: 'Terrace',
        balcony: 'Balcony',
        privateGarage: 'Private garage',
        communityGarage: 'Community garage',
        storageRoom: 'Storage room'
      },
      pricingPlan: {
        peaceOfMind: 'Peace of Mind Plan',
        secured: 'Secured Payment Plan'
      },
      state: {
        new: 'New',
        veryGood: 'Very good',
        good: 'Good',
        needsRefurbish: 'For refurbishment'
      },
      utilities: {
        electricity: 'Electricity',
        internet: 'WiFi',
        heating: 'Heating',
        insurance: 'Home insurance',
        water: 'Water'
      }
    },
    save: 'Save',
    search: 'Search',
    showLess: 'Show less',
    showMore: 'Show more',
    signIn: 'Sign in',
    rooms: 'Room',
    roomsAbbreviation: 'rooms'
  },
  forms: {
    email: 'Email',
    emailPlaceholder: "example{'@'}mail.com",
    fileInputBrowse: 'browse',
    fileInputFile: 'Drag and drop files or',
    fileInputImage: 'Drag and drop images or',
    fileInputMax: 'files max',
    fileInputMaxSize: 'Maximum size {0}MB',
    fullName: 'Name and surname',
    fullNamePlaceholder: 'Your first and last name',
    name: 'Name',
    namePlaceholder: 'Your first name',
    password: 'Password',
    passwordPlaceholder: 'Password',
    phone: 'Phone number',
    phonePlaceholder: 'Your phone number',
    surname: 'Surname',
    surnamePlaceholder: 'Your last name'
  },
  genericError: {
    unexpected: 'Something went wrong'
  },
  landlord: {
    documents: {
      description: {
        id: '(identity document)'
      },
      type: {
        id: 'NIF/NIE'
      },
      uploadInstructions: 'Supported formats: pdf, png, jpg.'
    }
  },
  listings: {
    applied: 'Property applied',
    idealistaLink: 'View on Idealista',
    instructions: {
      step1: {
        title: 'How to apply for a property visit?',
        description: `To apply for a visit, select the property you are interested in and complete the application by uploading the required documentation to validate your financial solvency and tenant’s debt status.

Once we have verified all the documentation, we will get in touch with you to coordinate the visit.`
      },
      step2: {
        title: 'Where can I find the property details?',
        description: `You can view the basic information of the property on the Vivara platform.

If you want the full details, go to the “Vivara Properties” section and click on “View on Idealista” for the property you are interested in. There, you will have access to all the available information.`
      }
    },
    request: 'Apply',
    title: 'Vivara Properties'
  },
  signin: {
    recoverPassword: {
      description: 'We will send you an email with a link so you can reset your password.',
      link: 'Have you forgotten your password?',
      title: 'Reset password',
      send: 'Reset password',
      success: {
        title: 'Request made successfully',
        description: `We have successfully received your request to reset your password. 
          
          You will shortly receive an email to complete the process. 
          
          Have you not received your email? Check your spam folder to make sure the email is not there.`
      }
    },
    resetPassword: {
      description: 'Your new password must be different from previously used passwords.',
      password: 'New password',
      passwordPlaceholder: 'Add a new password',
      passwordConfirmation: 'Confirm the new password',
      passwordConfirmationPlaceholder: 'Retype the password',
      save: 'Save the new password',
      title: 'Change password'
    }
  },
  signup: {
    account: {
      commercial:
        'I want to receive commercial communications and offers from Vivara or third parties tailored to my interests.',
      signInPrompt: 'Already have an account?',
      step: 'Your account information',
      terms: "I agree to Vivara Company's",
      termsEnd: '',
      landlord: {
        signUpWithEmail: 'Sign up with email',
        subtitle: '',
        title: {
          main: 'Add your {0}',
          bold: 'account information.'
        }
      },
      partner: {
        signUpWithEmail: 'Create Your Account',
        subtitle:
          'Make sure to use your business email address, as this is how we will initiate communication with you on Vivara.',
        title: {
          main: 'Create Your Account',
          bold: ''
        }
      },
      tenant: {
        signUpWithEmail: 'Sign up with email',
        subtitle: '',
        title: {
          main: 'Add your {0}',
          bold: 'account information.'
        }
      }
    },
    onboarding: {
      title: 'Create account'
    },
    personal: {
      step: 'Your personal information',
      landlord: {
        save: 'Coninue',
        title: {
          main: 'Add your {0}',
          bold: 'personal information.'
        }
      },
      partner: {
        save: 'Finish registration',
        title: {
          main: 'Complete your personal information',
          bold: ''
        }
      },
      tenant: {
        save: 'Continue',
        title: {
          main: 'Add your {0}',
          bold: 'personal information.'
        }
      }
    },
    property: {
      step: 'Property information'
    },
    type: {
      aLandlord: 'A landlord',
      aLandlordSub: 'I am looking for tenant(s) for my property',
      aTenant: 'A tenant',
      aTenantSub: 'I am looking for a property to rent',
      step: 'User type',
      title: {
        main: 'Are you a {0}',
        bold: 'landlord or a tenant?'
      }
    },
    welcome: 'Welcome to Vivara.'
  },
  profile: {
    password: {
      changePassword: 'Change password',
      confirmNewPassword: 'Confirm new password',
      confirmNewPasswordPlaceholder: 'Re-enter the password',
      description: 'Your new password must be different from previously used passwords.',
      newPassword: 'New password',
      newPasswordPlaceholder: 'Add a new password',
      title: 'Change password'
    },
    settings: {
      deleteAccount: 'Delete your account',
      deleteAccountConfirmation: {
        bold: 'Deleting your account is irreversible.',
        cancel: 'Cancel and return',
        confirm: 'Delete account',
        description:
          'This will delete all your data, and you will not be able to recover your account and associated information. If you are sure you want to continue, confirm below.'
      },
      email: 'Contact email',
      phone: 'Contact phone number',
      save: 'Save changes',
      title: 'View my profile'
    },
    help: {
      contact: 'Contact us',
      description:
        "If you need assistance, we are just a click away. Contact us and we'll be happy to help you with whatever you need.",
      title: 'Help'
    }
  },
  properties: {
    accountSuccess: {
      main: 'Your account has been created successfully.',
      bold: 'Would you like to keep addding your property characteristics?'
    },
    addProperty: 'Add a property',
    characteristicsSuccess: {
      main: 'Your property characteristics have been added successfully.',
      bold: 'Would you like to keep adding your property required documents?'
    },
    completeLater: 'Complete later',
    continueRegistration: 'Continue registration',
    infoBox:
      '<p>It would be good to prepare following documents for property onboarding:</p><ul><li>ID documents</li><li>Photographs of your home</li></ul><p>You can always save and continue later with your property onboarding.</p>',
    myProperties: 'My Properties',
    myProfile: 'My profile',
    onboardingCompleted: {
      bold: 'You have successfully completed the registration of your property and selected a Plan for your property.',
      finish: 'Take me to my property page',
      main: 'We will now review the information and inform you when it is published and the next steps to follow.'
    }
  },
  property: {
    basic: {
      pets: 'Are pets allowed in your property?',
      petsAllowed: 'Yes, the property accepts pets',
      petsNotAllowed: 'No, the property does not accept pets',
      roomInformation: {
        bathroom: 'Bathroom specifications',
        bathroomDescription:
          'Toilets should be counted as a separate bathroom if they are located outside the main bathroom.',
        bedroom: 'Bedroom specifications',
        bedroomDescription: 'According to the law, a room must be at least 5m2 to be considered a bedroom.'
      },
      rooms: 'What is the number of rooms in your property',
      state: 'Select the option that best describes your property state',
      step: 'General information',
      title: {
        main: 'Please, tell us about the {0} characteristics.',
        bold: 'basic'
      },
      type: 'Choose the option that best describes the type of property'
    },
    characteristics: {
      step: 'Property characteristics'
    },
    core: {
      addReference: 'Add reference',
      addressPlaceholder: 'Add the property address',
      availabilityDate: 'Availability date',
      availabilityTitle: {
        main: 'Please, let us know when your {0}',
        bold: 'property will be available for rent.'
      },
      availabilityType: 'Availability of the property',
      availabilityTypePlaceholder: 'Select the availability',
      catastralReferenceError: 'We couldn’t find the property with this catastral reference',
      catastralReferenceInformation:
        'The catastral reference number is 20-figure code that you can find in Sede Electronica del Catastro',
      catastralReferencePlaceholder: '1111111XXXXXXX1111XX',
      firstRent: 'Property available for rent for the first time',
      floorPlaceholder: 'Add the property floor',
      manualAddressToggle: 'I don’t have Cadastral reference, I will enter details manually',
      step: 'Property details',
      surface: 'Property surface',
      surfacePlaceholder: 'Add the property surface',
      rentAmount: 'Maximum monthly rent',
      rentTitle: {
        main: 'How much would you like to charge for {0}',
        bold: 'your monthly rent?'
      },
      usableSurface: 'Usable surface',
      usableSurfacePlaceholder: 'Add the property usable surface',
      usableSurfaceToggle: 'This is usable surface',
      title: {
        main: 'Add your {0}',
        bold: 'property information.'
      }
    },
    details: {
      description:
        'Here you can see the registered information of your property in Vivara. This view is read-only. If you wish to make modifications, please contact us.',
      documents: {
        title: 'Required Documentation'
      },
      characteristics: {
        bathrooms: 'Bathrooms',
        bedrooms: 'Bedrooms',
        equipment: 'Extra Equipment',
        furniture: 'Furniture',
        furnitureNo: 'Unfurnished',
        furnitureYes: 'Furnished',
        generalData: 'General Data',
        indoorExtras: 'Interior Extras',
        outdoorExtras: 'Exterior Extras',
        pets: 'Pets',
        petsNo: 'No, the property does not accept pets',
        petsYes: 'Yes, the property accepts pets',
        propertyType: 'Property Type',
        propertyCondition: 'Property Status',
        title: 'Property Features'
      },
      images: {
        needsPhotoService: "Vivara's Professional photography service",
        title: 'Image Gallery'
      },
      propertyData: {
        availabilityType: 'Availability',
        builtSize: 'Useful Surface',
        catastralReference: 'Cadastral Reference',
        floor: 'Floor',
        plan: 'Vivara Plan',
        street: 'Address',
        title: 'Property Data',
        rent: 'Monthly Rent'
      },
      title: 'Registered information'
    },
    documents: {
      description: {
        energyCertificate: '',
        habitabilityCertificate: '',
        propertyExpenses: '(property tax, homeowners association fees, waste disposal fee, parking permit, etc.)',
        utilityBill: '(water, electricity, and gas)'
      },
      step: 'Required documentation',
      title: {
        main: 'Please, add the {0}',
        bold: 'required documents.'
      },
      type: {
        energyCertificate: 'Energy certificate',
        habitabilityCertificate: 'Habitability certificate',
        propertyExpenses: 'Property expenses',
        utilityBill: 'Utility bill'
      },
      uploadInstructions: 'Supported formats: pdf, png, jpg.'
    },
    equipment: {
      indoorToggle: 'I’m renting my property without extras of interior.',
      indoorTitle: {
        main: 'Add details about your {0}',
        bold: 'indoor extras.'
      },
      furnished: 'Furnished',
      furnishedSubtitle: 'The property will be rented with furniture',
      furnitureTitle: {
        main: 'Please, tell us about the',
        bold: 'furnishing of your property.'
      },
      outdoorToggle: 'I’m renting my property without extras of exterior.',
      outdoorTitle: {
        main: 'Add details about your {0}',
        bold: 'outdoor extras.'
      },
      notFurnished: 'Not furnished',
      notFurnishedSubtitle: 'The property will be rented without furniture',
      step: 'Property equipment and extras'
    },
    images: {
      professionalPhotoService: 'I’m interested in Vivara’s service of professional photography',
      step: 'Image gallery',
      title: {
        main: 'Show us your entire property in current state by adding {0}',
        bold: 'photos.'
      },
      uploadInstructions: 'Supported formats: png, jpg, jpeg.'
    },
    onboarding: {
      continue: 'Continue',
      finish: 'Finish',
      saveAndExit: 'Save and exit',
      title: 'Register your property'
    },
    payment: {
      basic: {
        description: 'Rent your property with a simple, secure and worry-free process.',
        name: 'Basic Plan',
        price: '0,00€'
      },
      features: {
        digitalPlatform: 'Digital platform access.',
        portalPublish: 'Featured publication in real estate portals.',
        solvencyVerification: 'Exhaustive verification of tenants solvency and documentation.',
        tenantSelection: 'Tenant selection.',
        tenantReplacement:
          "Free tenant replacement for the first 12 months: if you lose your tenant, we'll find you another one.",
        digitalContract: 'Digital contract.',
        bondManagement: 'Complete management of the deposit: receipt, deposit and return at the end of the contract.',
        utilityTransfers: 'Change of ownership of utilities.',
        noPaymentCoverage: 'Coverage of defaults without a 12-month grace period, extendable to 18 and 24 months.',
        evictionCoverage: 'Legal coverage for evictions.',
        vandalismCoverage: 'Coverage against acts of vandalism up to €3,000.',
        repairManagement: 'Repair management.',
        incidentManagement: 'Incident management.',
        itpManagement: 'ITP presentation.',
        idealistaPublish: 'Featured publication in Idealista.',
        tenCandidates: 'Presentation of up to 10 verified candidates interested in your property.'
      },
      lessDetails: 'Less information',
      moreDetails: 'More information',
      peaceOfMind: {
        description: 'The plan that has it all. Marketing, default insurance and comprehensive management.',
        name: 'Peace of Mind Plan',
        price: '1 month + 8% of monthly rent plus IVA'
      },
      free: 'Free',
      title: {
        main: 'Choose the best {0}',
        bold: 'plan for your property.'
      },
      secured: {
        description: 'More than a guarantee; an insurance. Get paid on the 7th of every month, no matter what.',
        name: 'Secured Payment Plan',
        price: '5% of the monthly rent plus IVA'
      },
      termsAndConditions: {
        error: 'You must accept the Terms and conditions before finalizing the process',
        link: 'Terms and Conditions',
        readAndAccept: 'I have read and accept the'
      },
      step: 'Choose your plan'
    }
  },
  addFirstProperty: {
    intro: 'Hi {name}! Ready to add your first property?',
    sub: 'Add basic information about your property.',
    addProperty: 'Add a property'
  },
  infoFooter: {
    info1: 'Copyright © 2024. Todos los derechos reservados',
    info2: "Registre d'agents immobiliaris de Catalunya aicat 12678"
  },
  tenant: {
    add: 'Add another tenant',
    coTenant: 'Co-tenant',
    delete: {
      bold: "Are you sure you want to delete this tenant's information?",
      cancel: 'Cancel and return',
      confirm: 'Delete tenant',
      description:
        'All information and documents uploaded for this tenant will be permanently deleted. If you are sure, click on “Delete tenant”. Otherwise, you can cancel this action and keep the data.',
      title: 'Delete tenant'
    },
    documentation: 'Documentation',
    documents: {
      employmentContract: 'Employment contract',
      id: 'DNI/NIE (both sides)',
      lastForm100: 'Last model 100',
      lastForm130: 'Last model 130',
      payslip: 'Last two payslips',
      pensionRevaluationCertificate: 'Pension revaluation certificate',
      uploadInstructions: 'Supported formats: pdf, png, jpg.'
    },
    employmentStatus: 'Employment Status',
    employmentStatuses: {
      employed: 'Employed',
      'self-employed': 'Self-employed',
      retired: 'Retired'
    },
    employmentStatusDescription: 'Please, select your current employment status to view the required documents.',
    employmentStatusNotSelected: 'Select your employment status to see the documents you need to upload.',
    existingTenant: {
      cancel: 'Change email',
      confirm: 'Use existing data',
      description:
        'The tenant you are trying to add already has a Vivara account with the email address {email}. We can use their existing account data to automatically fill in some of the information for the application.',
      question: 'If you prefer not to use existing data, you can enter a different email address to add this tenant.',
      title: 'Tenant already registered'
    },
    exitWithoutSavingConfirmation: 'You have pending changes. Are you sure you want to exit?',
    mainTenant: 'Main tenant',
    partialSave: {
      confirm: 'Exit without documentation',
      description:
        'If you decide to leave now, the documentation you have uploaded for all co-tenants will not be saved, but we will keep the relevant information for each one. When you return, you will need to re-upload the required documentation for each co-tenant.',
      question: 'Are you sure you want to exit the process and re-upload the co-tenant documentation?',
      title: 'Partial saving of information'
    },
    requiredDocumentation: {
      employed: 'Required documentation for employees',
      'self-employed': 'Required documentation for self-employed',
      retired: 'Required documentation for retirees'
    }
  },
  validationError: {
    formatEmail: 'Please, enter a valid email address',
    formatPassword: 'Password must be between 6 and 20 characters in length',
    formatPhone: 'Please, enter a valid phone number',
    invalidDate: 'Please, enter a valid date',
    passwordsDoNotMatch: 'Passwords do not match',
    required: 'This information is required',
    requiredEmail: 'Email is required',
    requiredFullName: 'Name and surname are required',
    requiredName: 'Name is required',
    requiredPassword: 'Password is required',
    requiredPhone: 'Phone number is required',
    requiredSurname: 'Surname is required',
    requiredTermsAndConditions: 'You must accept the Terms and conditions'
  },
  propertyOnboardingProgress: {
    title: 'Register your Property',
    step1: {
      title: 'Property Details'
    },
    step2: {
      title: 'Property Features',
      sub1: 'General Information',
      sub2: 'Equipment and Extras',
      sub3: 'Image Gallery'
    },
    step3: {
      title: 'Required Documentation',
      sub1: 'ID (DNI)',
      sub2: 'Energy Certificate',
      sub3: 'Habitability Certificate',
      sub4: 'Property Tax (IBI)',
      sub5: 'Utility Bills'
    },
    step4: {
      title: 'Choose Your Plan'
    }
  }
}
