import type { UpdatePasswordForm } from '../domain/updatePasswordForm'
import type { UserRepository } from '../domain/userRepository'
import type { UserType } from '../domain/user'

export function resetPasswordBuilder(userRepository: UserRepository, validator: Validator<UpdatePasswordForm>) {
  return async (token: string, userType: UserType, primitive: Primitive<UpdatePasswordForm>) => {
    const form = validator(primitive)
    await userRepository.resetPassword(token, form.password, userType)
  }
}
