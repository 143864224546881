import type { Document } from '@/modules/shared/domain/document/document'
import type { Landlord } from './landlord'

export interface LandlordDocuments {
  id: Document[]
}

export function hasLandlordDocuments(landlord: Landlord): boolean {
  return landlord.documents.id.length > 0
}
