import { Help, Password, ProfileSettings } from '@/views'
import type { RouteRecordRaw } from 'vue-router'

export const profileRoutes: RouteRecordRaw[] = [
  {
    path: '/profile',
    name: 'profile',
    component: ProfileSettings
  },
  {
    path: '/password',
    name: 'password',
    component: Password
  },
  {
    path: '/help',
    name: 'help',
    component: Help
  }
]
