<template>
  <div class="item" :class="{ opened }">
    <RouterLink v-if="link && !active" class="element interactable" :to="{ name: link }" :class="{ active }">
      <img v-if="done" src="@/assets/images/icons/check_circle_filled.svg" />
      <img v-else src="@/assets/images/icons/check_circle_outline.svg" />
      <span>{{ name }}</span>
      <template v-if="children && !active && !scroll">
        <img v-if="opened" class="toggle" src="@/assets/images/icons/chevron_down.svg" />
        <img v-else class="toggle" src="@/assets/images/icons/chevron_up.svg" />
      </template>
    </RouterLink>
    <div v-else class="element" @click="opened = !opened" :class="{ active, interactable: children }">
      <img v-if="done" src="@/assets/images/icons/check_circle_filled.svg" />
      <img v-else src="@/assets/images/icons/check_circle_outline.svg" />
      <span>{{ name }}</span>
      <template v-if="children && !active && !scroll">
        <img v-if="opened" class="toggle" src="@/assets/images/icons/chevron_down.svg" />
        <img v-else class="toggle" src="@/assets/images/icons/chevron_up.svg" />
      </template>
    </div>
    <div class="children" v-if="active || opened">
      <div class="child" v-for="child in children" :class="{ active: child.active }">
        <img v-if="child.done" src="@/assets/images/icons/check.svg" />
        <a v-if="!child.active && scroll && child.link" :href="child.link">{{ child.name }}</a>
        <RouterLink v-else-if="!child.active && child.link" :to="{ name: child.link }">{{ child.name }}</RouterLink>
        <span v-else>{{ child.name }}</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

defineProps<{
  name: string
  active: boolean
  done?: boolean
  scroll?: boolean
  link?: string
  children?: { name: string; done?: boolean; active: boolean; link?: string }[]
}>()

const opened = ref(false)
</script>
<style scoped lang="sass">
.item

  .element
    display: flex
    align-items: center
    gap: 0.75rem
    padding: 0.75rem 0.5rem
    border-radius: 1rem
    border: 1px solid transparent
    user-select: none
    font-size: 0.875rem
    text-decoration: none

    .toggle
      margin-left: auto
      margin-right: 0.25rem

    &.interactable:not(.active)
      cursor: pointer

      &:hover
        border-color: #F0F0F0

    &:not(.active)
      img:not(.toggle)
        opacity: 0.35

    &.active
      span
        font-weight: bold

  .children
    .child
      position: relative

      a, span
        display: block
        padding: 0.5rem 0rem
        margin-left: 4rem
        user-select: none
        font-size: 0.875rem
        text-decoration: none

      &:not(.active)
        cursor: pointer

      &:hover, &.active
        font-weight: bold

      img
        position: absolute
        left: 2.75rem
        top: 0.9rem
</style>
