import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { object, string } from 'yup'
import { UserType } from '../domain/user'
import type { UpdateUserForm } from '../domain/updateUserForm'

export function yupUpdateUserFormValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<UpdateUserForm>({
      phone: string()
        .nullable()
        .notRequired()
        .test(
          'is-valid-phone',
          'validationError.formatPhone',
          (value) => !value || /^(?:\+34|0034|34)?[6789]\d{8}$/.test(value)
        ),
      name: string().required('validationError.requiredFullName'),
      type: string().required().oneOf(Object.values(UserType), 'validationError.required'),
      surname: string().nullable().notRequired()
    })
  )
}
