<template>
  <div class="main">
    <img src="@/assets/images/applications.png" />
    <h2>{{ t('applications.empty.title') }}</h2>
    <p class="title">{{ t('applications.empty.description') }}</p>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<style scoped lang="sass">
.main
  display: flex
  flex-direction: column
  align-items: center
  gap: 4rem

  h2
    text-align: center
</style>
