<template>
  <div v-if="model">
    <Title path="property.payment.title" />
    <div data-test-id="paymentPlanSelector">
      <PricingPlanOption
        v-if="basicPlanVisibility"
        :plan="PricingPlan.Basic"
        :selected="model.plan === PricingPlan.Basic"
        :opened="opened"
        @toggle="opened = !opened"
        @select="onSelect(PricingPlan.Basic)"
        free
      />
      <PricingPlanOption
        :plan="PricingPlan.Secured"
        :selected="model.plan === PricingPlan.Secured"
        :opened="opened"
        @toggle="opened = !opened"
        @select="onSelect(PricingPlan.Secured)"
      />
      <PricingPlanOption
        :plan="PricingPlan.PeaceOfMind"
        :selected="model.plan === PricingPlan.PeaceOfMind"
        :opened="opened"
        @toggle="opened = !opened"
        @select="onSelect(PricingPlan.PeaceOfMind)"
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FormSkeleton, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlordBasicPlanVisibility } from '@/modules/landlord/application'
import { PricingPlan, type PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { onMounted, ref, watch } from 'vue'
import PricingPlanOption from './PricingPlanOption.vue'

const model = defineModel<PropertyPaymentInformation>()

const { track } = useAnalytics()

const opened = ref(false)
const basicPlanVisibility = ref(false)

function onSelect(plan: PricingPlan) {
  if (!model.value) return

  model.value.plan = plan
  track(AnalyticsEvent.LandlordPropertyRegisterSelectPlan)
}

onMounted(async () => {
  basicPlanVisibility.value = await findCurrentLandlordBasicPlanVisibility()
})

watch(
  () => model.value,
  () => {
    if (model.value?.plan === PricingPlan.Basic) {
      basicPlanVisibility.value = true
    }
  }
)
</script>
