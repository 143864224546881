<template>
  <i18n-t :keypath="`${path}.main`" tag="h2" :for="`${path}.bold`" scope="global">
    <b>{{ t(`${path}.bold`) }}</b>
  </i18n-t>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps<{ path: string }>()

const { t } = useI18n()
</script>
