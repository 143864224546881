<template>
  <div class="card"></div>
</template>
<style scoped lang="sass">

@keyframes preloadAnimation
  0%
    background: #EEE
  50%
    background: #FFF
  100%
    background: #EEE

.card
  height: 20rem
  animation-name: preloadAnimation
  animation-duration: 2s
  animation-iteration-count: infinite
  animation-timing-function: linear
</style>
