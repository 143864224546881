import { findCurrentUser } from '@/modules/user/application'
import { Listings } from '@/views'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const listingsRoutes: RouteRecordRaw = {
  path: '/listings',
  name: 'listings',
  component: Listings,
  beforeEnter: listingsGuard,
  meta: { tenant: true }
}

export async function listingsGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = await findCurrentUser()
  return !user.name ? next({ name: 'personalInformation' }) : next()
}
