<template>
  <div class="input-wrapper">
    <div class="input" :class="{ error: error && error !== '' }">
      <label>{{ label }}</label>
      <input
        type="text"
        class="input-field"
        ref="input"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="disabled"
        @blur="onBlur"
      />
      <div class="icon">
        <img src="@/assets/images/icons/travel_explore.svg" alt="" />
      </div>
    </div>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
/// <reference types="@types/google.maps" />

import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  modelValue: Nullable<string>
  label?: string
  error?: string
  placeholder?: string
  disabled?: boolean
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Nullable<string>): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const input = ref<HTMLInputElement | null>(null)

onMounted(async () => {
  if (!input.value || !google) return

  const autocomplete = new google.maps.places.Autocomplete(input.value, {
    componentRestrictions: { country: 'es' },
    fields: ['formatted_address']
  })

  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace()
    const inputValue = input.value?.value ?? null
    emit('update:modelValue', place.formatted_address ?? inputValue)
    if (props.trackEnterEvent) {
      track(props.trackEnterEvent)
    }
  })
})

function onBlur() {
  emit('update:modelValue', input.value?.value ?? null)
}
</script>
<style scoped lang="scss">
.input-wrapper {
  width: 100%;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
  }

  &.error {
    .input-field {
      border: 1px solid red;
    }
  }

  .input-field {
    box-sizing: border-box;
    height: 2.75rem;
    padding: 0.75rem 0.5rem;
    border: 1px solid $lightBorder;
    border-radius: 6px;
    font-size: 1rem;

    &::placeholder {
      color: $grey300;
      font-weight: 300;
      font-size: 0.875rem;
    }
    &:disabled {
      background-color: $grey200;
    }
  }

  .icon {
    position: absolute;
    right: 0.8rem;
    bottom: 0.3rem;
  }
}
</style>
