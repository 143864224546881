import { UserType } from '@/modules/user/domain/user'
import { AccountInformation, PersonalInformation, PropertyInformation, TypeInformation } from '@/views'
import type { RouteRecordRaw } from 'vue-router'

export const signUpRoutes: RouteRecordRaw = {
  path: '/register',
  name: 'signUp',
  redirect: '/register/type',
  beforeEnter: (to, from, next) => {
    if (to.query.role === UserType.Tenant) {
      next({ name: 'accountInformation', params: { type: UserType.Tenant } })
    } else if (to.query.role === UserType.Landlord) {
      next({ name: 'accountInformation', params: { type: UserType.Landlord } })
    } else {
      next()
    }
  },
  children: [
    {
      path: 'type',
      name: 'typeInformation',
      component: TypeInformation,
      meta: { signedOut: true }
    },
    {
      path: 'account/:type',
      name: 'accountInformation',
      component: AccountInformation,
      meta: { signedOut: true },
      beforeEnter: (to, from, next) => {
        if (Object.values(UserType).includes(to.params.type as UserType)) {
          next()
        } else {
          next({ name: 'typeInformation' })
        }
      }
    },
    {
      path: 'personal',
      name: 'personalInformation',
      component: PersonalInformation
    },
    {
      path: 'property/:id',
      name: 'propertyInformation',
      component: PropertyInformation
    }
  ]
}
