import type { Document } from '@/modules/shared/domain/document/document'
import type { Tenant } from '../domain/tenant'
import { TenantDocumentType } from '../domain/tenantDocuments'
import { TenantEmploymentStatus } from '../domain/tenantEmploymentStatus'

export interface TenantDocumentDto {
  id: string
  tenantId: string
  name: string
  type: string
}

export interface TenantDto {
  email: Nullable<string>
  employmentStatus: Nullable<'employed' | 'retired' | 'selfEmployed'>
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
}

export interface CoTenantDto {
  email: Nullable<string>
  workingStatus: Nullable<TenantEmploymentStatus>
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
}

export function fromDocumentDto(dto: TenantDocumentDto): Document {
  return { id: dto.id, name: dto.name, source: null, uri: null }
}

export function fromDocumentDtos(files: TenantDocumentDto[]): Record<TenantDocumentType, Document[]> {
  function mapFileType(type: string): Document[] {
    return files.filter((file) => file.type === type).map(fromDocumentDto)
  }

  return {
    employmentContract: mapFileType('employment-contract'),
    id: mapFileType('personal-id'),
    lastForm100: mapFileType('form-100'),
    lastForm130: mapFileType('form-130'),
    payslip: mapFileType('payslip'),
    pensionRevaluationCertificate: mapFileType('pension-revaluation-certificate')
  }
}

export function fromCoTenantDto(dto: CoTenantDto): Tenant {
  return {
    email: dto.email,
    employmentStatus: dto.workingStatus,
    id: dto.id,
    name: dto.name,
    phone: dto.phone,
    surname: dto.surname,
    documents: fromDocumentDtos([])
  }
}

export function fromTenantDto(dto: TenantDto, documentDtos: TenantDocumentDto[]): Tenant {
  let employmentStatus: Nullable<TenantEmploymentStatus> = null
  if (dto.employmentStatus === 'employed') {
    employmentStatus = TenantEmploymentStatus.Employed
  } else if (dto.employmentStatus === 'selfEmployed') {
    employmentStatus = TenantEmploymentStatus.SelfEmployed
  } else if (dto.employmentStatus === 'retired') {
    employmentStatus = TenantEmploymentStatus.Retired
  }

  return {
    email: dto.email,
    employmentStatus,
    id: dto.id,
    name: dto.name,
    phone: dto.phone,
    surname: dto.surname,
    documents: fromDocumentDtos(documentDtos)
  }
}
