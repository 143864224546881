<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <PropertyImagesForm v-model="images" :id="id" />
      </div>
    </div>
  </div>
  <Teleport to="#userLayoutFooter" v-if="landlord && property">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter
      v-if="!showOnboardingCompletedModal"
      :loading="loading"
      :landlord="landlord"
      :property="property"
      @continue="onContinue"
      @finish="onFinish"
      @exit="onExit"
    />
  </Teleport>
</template>
<script lang="ts" setup>
import { OnboardingFooter, OnboardingCompletedModal, PropertyImagesForm, PropertyStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById, finishPropertyOnboarding, updatePropertyImages } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyImages } from '@/modules/property/domain/propertyImages'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const images = ref<PropertyImages | undefined>()
const showOnboardingCompletedModal = ref(false)

const loading = ref(false)

track(AnalyticsEvent.LandlordPropertyRegisterStartImages)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  images.value = { ...property.value.images }
})

async function save() {
  if (!images.value) return

  loading.value = true
  try {
    await updatePropertyImages(id, images.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitImages)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'propertyDocuments' })
}

async function onFinish() {
  await save()
  await finishPropertyOnboarding(id)
  track(AnalyticsEvent.LandlordOnboardingCompleted)
  showOnboardingCompletedModal.value = true
}
</script>
