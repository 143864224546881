<template>
  <Modal v-if="opened" @close="opened = false" data-test-id="applicationUpdatedModal">
    <TypeformForm :formId="formId" />
  </Modal>
</template>
<script setup lang="ts">
import { Modal, TypeformForm } from '@/components'
import { router } from '@/router'
import { ref } from 'vue'

const formId = import.meta.env.VITE_TYPEFORM_ID_APPLICATION_UPDATED as string
const applicationUpdated = router.currentRoute.value.query['applicationUpdated'] !== undefined
const opened = ref(applicationUpdated)
</script>
