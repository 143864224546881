import type { ValidationError, ObjectSchema } from 'yup'
import { FormValidationError } from '../../domain/errors/formValidationError'

export function yupGenericSchemaValidatorBuilder<T>(schema: ObjectSchema<{}, T, {}, ''>): Validator<T> {
  return (primitive: Primitive<T>) => {
    try {
      return schema.validateSync(primitive, { abortEarly: false }) as T
    } catch (e) {
      const error = e as ValidationError
      const errors: Record<string, string> = {}
      error.inner.forEach(({ path, message }) => (errors[path as string] = message))
      throw new FormValidationError(errors)
    }
  }
}
