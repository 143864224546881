import type { PropertyBasicInformation } from '../domain/propertyBasicInformation'
import type { PropertyRepository } from '../domain/propertyRepository'

export function updateBasicInformationBuilder(
  propertyRepository: PropertyRepository,
  validator: Validator<PropertyBasicInformation>
) {
  return async (id: string, primitive: Primitive<PropertyBasicInformation>) => {
    const basicInformation = validator(primitive)
    await propertyRepository.updateBasicInformation(id, basicInformation)
  }
}
