import type { AuthService } from '@/modules/auth/domain/authService'
import type { UpdateUserForm } from '../domain/updateUserForm'
import type { UserRepository } from '../domain/userRepository'

export function updateCurrentBuilder(
  userRepository: UserRepository,
  authService: AuthService,
  validator: Validator<UpdateUserForm>
) {
  return async (primitive: Primitive<UpdateUserForm>) => {
    const form = validator(primitive)
    const userId = await authService.getUserId()
    await userRepository.update(userId, form)
  }
}
