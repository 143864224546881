import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { array, object } from 'yup'
import type { LandlordDocuments } from '../domain/landlordDocuments'

export function yupLandlordDocumentsValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<LandlordDocuments>({
      id: array().nullable()
    })
  )
}
