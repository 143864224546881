export class FormValidationError extends Error {
  public errors: Record<string, string>

  constructor(errors: Record<string, string>) {
    super('FormValidationError')
    this.name = 'FormValidationError'
    this.errors = errors

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormValidationError)
    }
  }
}
