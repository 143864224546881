<template>
  <div class="flex column gap-2" v-if="model">
    <div data-test-id="furnitureSelector">
      <Title class="mb-1" path="property.equipment.furnitureTitle" />
      <ParagraphSelector
        v-model="model.furniture"
        :options="furnitureOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectFurniture"
      />
    </div>
    <div data-test-id="indoorEquipmentSelector">
      <Title class="mb-1 mt-1" path="property.equipment.indoorTitle" />
      <ChipMultiselector
        v-model="model.indoorExtras"
        :options="indoorOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectIndoorExtras"
      />
    </div>
    <div data-test-id="outdoorEquipmentSelector">
      <Title class="mb-1 mt-1" path="property.equipment.outdoorTitle" />
      <ChipMultiselector
        v-model="model.outdoorExtras"
        :options="outdoorOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectOutdoorExtras"
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { ChipMultiselector, FormSkeleton, ParagraphSelector, Title } from '@/components'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import {
  IndoorExtra,
  OutdoorExtra,
  type PropertyEquipmentInformation
} from '@/modules/property/domain/propertyEquipmentInformation'
import { useI18n } from 'vue-i18n'

const model = defineModel<PropertyEquipmentInformation>()

const { t } = useI18n()

const furnitureOptions = [
  {
    value: true,
    label: 'property.equipment.furnished',
    subtitle: 'property.equipment.furnishedSubtitle'
  },
  {
    value: false,
    label: 'property.equipment.notFurnished',
    subtitle: 'property.equipment.notFurnishedSubtitle'
  }
]

const indoorOptions = Object.values(IndoorExtra).map((value) => ({
  value,
  label: `common.property.indoorExtras.${value}`
}))

const outdoorOptions = Object.values(OutdoorExtra).map((value) => ({
  value,
  label: `common.property.outdoorExtras.${value}`
}))
</script>
