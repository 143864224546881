import type { UserRepository } from '../domain/userRepository'
import type { AuthService } from '@/modules/auth/domain/authService'

export function deleteCurrentBuilder(userRepository: UserRepository, authService: AuthService) {
  return async () => {
    const userId = await authService.getUserId()
    const user = await userRepository.get(userId)
    await userRepository.delete(user)
  }
}
