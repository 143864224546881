<template>
  <div class="card flex column gap-1" ref="card">
    <div class="flex justify-between align-center">
      <p class="title" v-if="isMainTenant">{{ t('tenant.mainTenant') }}</p>
      <template v-else>
        <p class="title">{{ t('tenant.coTenant') }} {{ index }}</p>
        <DeleteTenantButton :applicationId="applicationId" :tenant="model" @deleted="emit('deleted')" />
      </template>
    </div>
    <TenantForm v-model="model" :collapsed="collapsed" :isMainTenant="isMainTenant" :errors="errors" />
    <TenantDocumentsForm v-if="!collapsed" v-model="model" @delete="onDeleteDocument" />
    <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">{{ t(errors['generic']) }}</p>
    <TenantCardToggle :collapsed="collapsed" :tenant="model" @toggle="collapsed = !collapsed" />
  </div>
</template>
<script lang="ts" setup>
import { DeleteTenantButton, TenantCardToggle, TenantDocumentsForm, TenantForm } from '@/components'
import { linkTenantToApplication } from '@/modules/application/application'
import type { Document } from '@/modules/shared/domain/document/document'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import { createTenant, deleteTenantDocument, updateTenant, updateTenantDocuments } from '@/modules/tenant/application'
import { isTenantCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { asyncForEach } from '@/utils/array'
import { pendingChangesCheckerBuilder } from '@/utils/pendingChangesChecker'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })
const props = defineProps<{ index: number; applicationId: Uuid }>()
const emit = defineEmits(['deleted'])

defineExpose({ save, checkPendingChanges })

const { t } = useI18n()

let documentIdsToDelete: string[] = []
const card = ref<Nullable<HTMLElement>>(null)
const errors = ref<Record<string, string>>({})
const collapsed = ref(isTenantCompleted(model.value))
const pendingChangesChecker = pendingChangesCheckerBuilder(model.value)
const isMainTenant = computed(() => props.index === 0)

function onDeleteDocument(documentToDelete: Document) {
  if (documentToDelete.id) {
    documentIdsToDelete.push(documentToDelete.id)
  }
}

function discardFiles() {
  model.value.documents = {
    employmentContract: [],
    id: [],
    payslip: [],
    lastForm100: [],
    lastForm130: [],
    pensionRevaluationCertificate: []
  }
}

function checkPendingChanges() {
  return !model.value.id || pendingChangesChecker.hasPendingChanges(model.value)
}

async function save(mustDiscardFiles?: boolean) {
  if (!checkPendingChanges()) return

  errors.value = {}
  try {
    let tenantId = model.value.id
    if (!tenantId) {
      tenantId = model.value.id = await createTenant(model.value)
    }
    if (!isMainTenant.value) {
      await linkTenantToApplication(props.applicationId, tenantId)
    }
    if (!isMainTenant.value && mustDiscardFiles) {
      discardFiles()
    }
    await updateTenant(tenantId, model.value)
    await asyncForEach(documentIdsToDelete, (documentId) => deleteTenantDocument(tenantId, documentId))
    await updateTenantDocuments(tenantId, model.value.documents)
    pendingChangesChecker.setLatestChanges(model.value)
    documentIdsToDelete = []
  } catch (error) {
    collapsed.value = false
    card.value?.scrollIntoView({ behavior: 'smooth' })
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    throw error
  }
}
</script>
