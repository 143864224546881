<template>
  <div class="tooltip">
    <slot name="handle"></slot>
    <div class="tooltip-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.tooltip
  position: relative

  .tooltip-content
    display: none
    position: absolute
    top: -0.5rem
    right: -0.5rem
    background-color: white
    box-shadow: 0px 2px 6px 2px #00000026
    border-radius: 0.75rem
    padding: 1rem

  &:hover
    .tooltip-content
      display: block
</style>
