import { TenantsInformation, ApplicationDetails } from '@/views'
import type { RouteRecordRaw } from 'vue-router'

export const applicationRoutes: RouteRecordRaw = {
  path: '/application/:id',
  name: 'application',
  meta: { tenant: true },
  children: [
    {
      path: 'tenants',
      name: 'tenantsInformation',
      component: TenantsInformation
    },
    {
      path: 'details',
      name: 'applicationDetails',
      component: ApplicationDetails
    }
  ]
}
