import type { PropertyEquipmentInformation } from '../domain/propertyEquipmentInformation'
import type { PropertyRepository } from '../domain/propertyRepository'

export function updateEquipmentInformationBuilder(
  propertyRepository: PropertyRepository,
  validator: Validator<PropertyEquipmentInformation>
) {
  return async (id: string, primitive: Primitive<PropertyEquipmentInformation>) => {
    const equipmentInformation = validator(primitive)
    await propertyRepository.updateEquipmentInformation(id, equipmentInformation)
  }
}
