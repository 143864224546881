import type { AuthService } from '@/modules/auth/domain/authService'
import type { Document } from '@/modules/shared/domain/document/document'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import { asyncForEach, asyncMap } from '@/utils/array'
import type { AxiosInstance } from 'axios'
import type { TenantDocuments } from '../domain/tenantDocuments'
import type { TenantRepository } from '../domain/tenantRepository'
import type { UpdateTenantForm } from '../domain/updateTenantForm'
import {
  fromCoTenantDto,
  fromDocumentDto,
  fromTenantDto,
  type CoTenantDto,
  type TenantDocumentDto,
  type TenantDto
} from './apiDto'

export function apiTenantRepositoryBuilder(
  apiService: AxiosInstance,
  apiV2Service: AxiosInstance,
  authService: AuthService
): TenantRepository {
  async function updateDocuments(documents: TenantDocuments, coTenantId?: string): Promise<TenantDocuments> {
    const id = await uploadDocuments('personal-id', documents.id, coTenantId)
    const payslip = await uploadDocuments('payslip', documents.payslip, coTenantId)
    const lastForm100 = await uploadDocuments('form-100', documents.lastForm100, coTenantId)
    const lastForm130 = await uploadDocuments('form-130', documents.lastForm130, coTenantId)
    const employmentContract = await uploadDocuments('employment-contract', documents.employmentContract, coTenantId)
    const pensionRevaluationCertificate = await uploadDocuments(
      'pension-revaluation-certificate',
      documents.pensionRevaluationCertificate,
      coTenantId
    )

    return {
      employmentContract,
      id,
      lastForm100,
      lastForm130,
      payslip,
      pensionRevaluationCertificate
    }
  }

  async function uploadDocuments(type: string, documents: Document[], coTenantId?: string): Promise<Document[]> {
    const filesToUpload = documents.filter((file) => file.source).map((file) => file.source as File)

    if (filesToUpload.length === 0) {
      return documents
    }

    await asyncForEach(filesToUpload, async (file) => {
      const uploadEndpoint = coTenantId
        ? `/onboarding/co-tenants/${coTenantId}/files/${type}`
        : `/onboarding/tenants/me/files/${type}`
      const formData = new FormData()
      formData.append('file', file)
      await apiV2Service.post(uploadEndpoint, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    })

    const getEndpoint = coTenantId ? `/onboarding/co-tenants/${coTenantId}/files` : '/onboarding/tenants/me/files'
    const dtos = (await apiV2Service.get(getEndpoint)) as { data: TenantDocumentDto[] }
    return dtos.data.map(fromDocumentDto)
  }

  return {
    async create(tenantId: Uuid, { name, surname, email, phone }: UpdateTenantForm) {
      await apiService.post(`/tenants/${tenantId}`, { name, surname, email, phone })
    },
    async deleteDocument(tenantId: Uuid, documentId: string) {
      const userId = await authService.getUserId()
      if (userId === tenantId) {
        return apiV2Service.delete(`/onboarding/tenants/me/files/${documentId}`)
      } else {
        return apiV2Service.delete(`/onboarding/co-tenant-files/${documentId}`)
      }
    },
    async getByApplication(applicationId: Uuid) {
      const userId = await authService.getUserId()
      const mainTenantDto = (await apiService.get(`/tenants/${userId}`)) as TenantDto
      const mainTenantDocumentDtos = (await apiV2Service.get('/onboarding/tenants/me/files')) as {
        data: TenantDocumentDto[]
      }

      const coTenantIds = (await apiV2Service.get(`/onboarding/applications/${applicationId}/co-tenants`)) as {
        data: { coTenantId: string }[]
      }
      const coTenants = await asyncMap(coTenantIds.data, async ({ coTenantId }) => {
        const coTenantDto = (await apiV2Service.get(`/onboarding/co-tenants/${coTenantId}`)) as CoTenantDto
        return fromCoTenantDto(coTenantDto)
      })

      return [fromTenantDto(mainTenantDto, mainTenantDocumentDtos.data), ...coTenants]
    },
    async getByEmail(email: string) {
      try {
        const dto = (await apiV2Service.get(
          `/onboarding/co-tenants/email-search?value=${encodeURIComponent(email)}`
        )) as CoTenantDto
        return fromCoTenantDto(dto)
      } catch {
        return null
      }
    },
    async getLead() {
      return apiV2Service.get('/leads/tenants/me')
    },
    async update(tenantId: Uuid, form: UpdateTenantForm) {
      const userId = await authService.getUserId()
      const { employmentStatus, name, phone, surname } = form

      if (userId === tenantId) {
        await apiService.patch(`/tenants/${userId}`, { name, phone, surname })
        if (employmentStatus !== null) {
          await apiV2Service.put('/onboarding/tenants/me/working-status', { workingStatus: employmentStatus })
        }
      } else if (employmentStatus !== null) {
        await apiV2Service.put(`/onboarding/co-tenants/${tenantId}/working-status`, { workingStatus: employmentStatus })
      }
    },
    async updateDocuments(tenantId: Uuid, documents: TenantDocuments) {
      const userId = await authService.getUserId()
      if (userId === tenantId) {
        return updateDocuments(documents)
      } else {
        const coTenantDocumentDtos = (await apiV2Service.get(`/onboarding/co-tenants/${tenantId}/files`)) as {
          data: { id: string }[]
        }
        await asyncForEach(coTenantDocumentDtos.data, async ({ id }) => {
          try {
            apiV2Service.delete(`/onboarding/co-tenant-files/${id}`)
          } catch {}
        })
        return updateDocuments(documents, tenantId)
      }
    }
  }
}
