import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { array, boolean, object, string } from 'yup'
import type { PropertyEquipmentInformation } from '../domain/propertyEquipmentInformation'

export function yupPropertyEquipmentInformationValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyEquipmentInformation>({
      furniture: boolean().nullable(),
      indoorExtras: array().of(string()),
      outdoorExtras: array().of(string())
    })
  )
}
