<template>
  <div id="app">
    <FullScreenLoader v-if="loading" />
    <template v-else>
      <UserLayout v-if="user" :user="user">
        <RouterView />
      </UserLayout>
      <BasicLayout v-else>
        <RouterView />
      </BasicLayout>
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { BasicLayout, FullScreenLoader, UserLayout } from './components'
import { findCurrentUser } from './modules/user/application'
import type { User } from './modules/user/domain/user'

const route = useRoute()
const loading = ref(true)
const user = ref<Nullable<User>>(null)

onMounted(async () => {
  try {
    user.value = await findCurrentUser()
  } catch {}
  loading.value = false
})

watch(route, async ({ meta }) => {
  if (meta.signedOut) {
    user.value = null
  } else if (user.value === null) {
    user.value = await findCurrentUser()
  }
})
</script>
<style lang="sass">
@import "@/assets/styles/main.sass"

#app
  position: relative
  min-height: 100vh
</style>
