import { v4 as uuidv4, validate } from 'uuid'

export type Uuid = string

export function createUuid(uuid: string): Uuid {
  if (!validate(uuid)) {
    throw new Error()
  }

  return uuid
}

export function generateUuid(): Uuid {
  return uuidv4()
}
