<template>
  <Modal mandatory>
    <div class="flex align-center justify-center column gap-1 p-5 primary" data-test-id="onboardingCompletedModal">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('common.congratulations') }}</h2>
      <p class="pl-2 pr-2">
        <b>{{ t('properties.onboardingCompleted.bold') }}</b>
        {{ t('properties.onboardingCompleted.main') }}
      </p>
      <img src="@/assets/images/curvedArrow.png" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <RouterLink :to="{ name: 'properties' }" @click="track(AnalyticsEvent.LandlordOnboardingPropertiesList)">
        <Button>{{ t('properties.onboardingCompleted.finish') }}</Button>
      </RouterLink>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()
</script>
