import type { AxiosInstance } from 'axios'
import type { ListingRepository } from '../domain/listingRepository'
import { fromListingDto, type ListingDto, type ListingImageDto } from './apiDto'
import { asyncMap } from '@/utils/array'

export function apiListingRepositoryBuilder(apiV2Service: AxiosInstance): ListingRepository {
  return {
    async getAll() {
      const response = (await apiV2Service.get(`/onboarding/properties`)) as { data: ListingDto[] }
      return asyncMap(response.data, async (dto) => {
        const images = (await apiV2Service.get(`/onboarding/properties/${dto.id}/images`)) as {
          data: ListingImageDto[]
        }
        return fromListingDto(dto, images.data)
      })
    }
  }
}
