<template>
  <div class="or-separator">
    <span></span>
    <span>{{ t('common.or') }}</span>
    <span></span>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<style lang="scss" scoped>
.or-separator {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  span {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  span:first-child,
  span:last-child {
    border-bottom: 1px solid $grey200;
    height: 0;
    width: 50%;
  }
}
</style>
