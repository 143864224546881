import type { Storage } from '../../domain/storage/storage'

export function booleanStorageBuilder(key: string): Storage<boolean> {
  return {
    get() {
      return localStorage.getItem(key) === 'true'
    },
    set(value: boolean) {
      localStorage.setItem(key, value ? 'true' : 'false')
    }
  }
}
