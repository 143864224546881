import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { array, boolean, object } from 'yup'
import type { PropertyImages } from '../domain/propertyImages'

export function yupPropertyImagesValidatorBuilder(): Validator<PropertyImages> {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyImages>({
      needsPhotoService: boolean().nullable(),
      files: array().nullable()
    })
  )
}
