export enum PricingPlan {
  Basic = 'basic',
  Secured = 'secured',
  PeaceOfMind = 'peaceOfMind'
}

export enum PricingPlanFeature {
  DigitalPlatform = 'digitalPlatform',
  PortalPublish = 'portalPublish',
  SolvencyVerification = 'solvencyVerification',
  TenantSelection = 'tenantSelection',
  TenantReplacement = 'tenantReplacement',
  DigitalContract = 'digitalContract',
  BondManagement = 'bondManagement',
  UtilityTransfers = 'utilityTransfers',
  NoPaymentCoverage = 'noPaymentCoverage',
  EvictionCoverage = 'evictionCoverage',
  VandalismCoverage = 'vandalismCoverage',
  RepairManagement = 'repairManagement',
  IncidentManagement = 'incidentManagement',
  ItpManagement = 'itpManagement',
  IdealistaPublish = 'idealistaPublish',
  TenCandidates = 'tenCandidates'
}

export function isFeatureIncludedInPricingPlan(feature: PricingPlanFeature, plan: PricingPlan): boolean {
  const propertyPlanFeatures: Record<PricingPlan, PricingPlanFeature[]> = {
    [PricingPlan.Basic]: [
      PricingPlanFeature.DigitalPlatform,
      PricingPlanFeature.IdealistaPublish,
      PricingPlanFeature.SolvencyVerification,
      PricingPlanFeature.TenCandidates
    ],
    [PricingPlan.Secured]: [
      PricingPlanFeature.DigitalPlatform,
      PricingPlanFeature.NoPaymentCoverage,
      PricingPlanFeature.EvictionCoverage,
      PricingPlanFeature.VandalismCoverage,
      PricingPlanFeature.DigitalContract,
      PricingPlanFeature.BondManagement,
      PricingPlanFeature.UtilityTransfers
    ],
    [PricingPlan.PeaceOfMind]: [
      PricingPlanFeature.DigitalPlatform,
      PricingPlanFeature.PortalPublish,
      PricingPlanFeature.SolvencyVerification,
      PricingPlanFeature.TenantSelection,
      PricingPlanFeature.TenantReplacement,
      PricingPlanFeature.DigitalContract,
      PricingPlanFeature.BondManagement,
      PricingPlanFeature.UtilityTransfers,
      PricingPlanFeature.NoPaymentCoverage,
      PricingPlanFeature.EvictionCoverage,
      PricingPlanFeature.VandalismCoverage,
      PricingPlanFeature.RepairManagement,
      PricingPlanFeature.IncidentManagement,
      PricingPlanFeature.ItpManagement
    ]
  }

  return propertyPlanFeatures[plan].includes(feature)
}

export interface PropertyPaymentInformation {
  plan: Nullable<PricingPlan>
}
