<template>
  <div class="options" :class="{ disabled }">
    <div
      v-for="option in options"
      class="option"
      :class="{ selected: modelValue.includes(option.value) }"
      @click="toggle(option)"
    >
      <div class="wrapper">
        <p>{{ t(option.label) }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAnalytics } from '@/hooks/useAnalytics'
import type { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

type Option = { value: string; label: string }

const props = defineProps<{
  modelValue: string[]
  options: Option[]
  disabled?: boolean
  trackSelectEvent?: AnalyticsEvent
}>()

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { track } = useAnalytics()

function toggle(option: Option) {
  if (props.disabled) {
    return
  }

  if (props.modelValue.includes(option.value)) {
    emit(
      'update:modelValue',
      props.modelValue.filter((value) => value !== option.value)
    )
  } else {
    emit('update:modelValue', [...props.modelValue, option.value])
    if (props.trackSelectEvent) {
      track(props.trackSelectEvent)
    }
  }
}
</script>
<style lang="sass" scoped>
.options
  display: flex
  flex-wrap: wrap
  gap: 0.5rem

  .option
    text-align: center

    .wrapper
      box-sizing: border-box
      font-size: 0.875rem
      padding: 0.5rem 1rem
      border-radius: 0.5rem
      border: 1px solid #d4d4d4

      p
        line-height: 1.5rem



.options.disabled
  opacity: 0.5

.options:not(.disabled)
  .wrapper:hover
    cursor: pointer
    border-color: #0B0B0B
  .option.selected
    .wrapper
      background-color: #F6F3A0
</style>
