<template>
  <div class="options">
    <div
      v-for="option in options"
      class="option"
      :class="{ selected: modelValue === option.value, square: option.image !== undefined }"
      @click="select(option)"
      :data-test-id="option.value"
    >
      <div class="wrapper">
        <img v-if="option.image" :src="option.image" :alt="t(option.label)" />
        <p v-else>{{ t(option.label) }}</p>
      </div>
      <p v-if="option.image" class="subtitle">{{ t(option.label) }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

type Option = { value: string; label: string; image?: string }

const props = defineProps<{
  modelValue: Nullable<string>
  options: Option[]
  trackSelectEvent?: AnalyticsEvent
}>()

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { track } = useAnalytics()

function select(option: Option) {
  emit('update:modelValue', option.value)
  if (props.trackSelectEvent) {
    track(props.trackSelectEvent)
  }
}
</script>
<style lang="sass" scoped>
.options
  display: flex
  flex-wrap: wrap
  gap: 0.5rem

  .option
    text-align: center

    &.square
      width: 5.5rem
      .wrapper
        padding: 1.6em 1rem

    .subtitle
      font-size: 0.875rem
      color: #9F9F9F
      margin-top: 0.25rem

    .wrapper
      box-sizing: border-box
      font-size: 0.875rem
      padding: 0.5rem 1rem
      border-radius: 0.5rem
      border: 1px solid #d4d4d4

      p
        line-height: 1.5rem

    &.selected
      .wrapper
        cursor: default
        background-color: #F6F3A0

      .subtitle
        color: #0B0B0B

    &:not(.selected)
      .wrapper:hover
        cursor: pointer
        border-color: #0B0B0B
</style>
