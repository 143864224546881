import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import { asyncMap } from '@/utils/array'
import type { AxiosInstance } from 'axios'
import type { ApplicationStatus } from '../domain/application'
import type { ApplicationRepository } from '../domain/applicationRepository'
import { fromDto, type ApplicationApiDto, type PropertyApiDto, type PropertyImageDto } from './apiDto'

export function apiApplicationRepositoryBuilder(apiV2Service: AxiosInstance): ApplicationRepository {
  return {
    async create(applicationId: Uuid, propertyId: string) {
      await apiV2Service.post('/onboarding/applications', { id: applicationId, propertyId })
    },
    async getAll() {
      const result = (await apiV2Service.get('/onboarding/tenants/me/applications?page=1&pageSize=50')) as {
        data: ApplicationApiDto[]
      }
      return asyncMap(result.data, async (dto) => {
        const propertyDto = (await apiV2Service.get(`/onboarding/properties/${dto.propertyId}`)) as PropertyApiDto
        const images = (await apiV2Service.get(`/onboarding/properties/${dto.propertyId}/images`)) as {
          data: PropertyImageDto[]
        }
        return fromDto(dto, propertyDto, images.data)
      })
    },
    async getStatusById(applicationId: Uuid) {
      const dto = (await apiV2Service.get(`/onboarding/tenants/me/applications/${applicationId}`)) as ApplicationApiDto
      return dto.status as ApplicationStatus
    },
    async linkTenant(applicationId: Uuid, tenantId: Uuid) {
      await apiV2Service.put(`/onboarding/applications/${applicationId}/co-tenants/${tenantId}`)
    },
    async unlinkTenant(applicationId: Uuid, tenantId: Uuid) {
      return apiV2Service.delete(`/onboarding/applications/${applicationId}/co-tenants/${tenantId}`)
    },
    async submit(applicationId: Uuid) {
      await apiV2Service.put(`/onboarding/tenants/me/applications/${applicationId}/submit`)
    }
  }
}
