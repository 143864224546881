export enum PropertyType {
  Flat = 'flat',
  Penthouse = 'penthouse',
  Duplex = 'duplex',
  AttachedSemiDetached = 'attachedSemiDetached',
  DetachedHouse = 'detachedHouse'
}

export enum PropertyCondition {
  New = 'new',
  VeryGood = 'veryGood',
  Good = 'good',
  NeedsRefurbish = 'needsRefurbish'
}

export interface PropertyBasicInformation {
  bathrooms: Nullable<number>
  bedrooms: Nullable<number>
  pets: Nullable<boolean>
  propertyCondition: Nullable<PropertyCondition>
  propertyType: Nullable<PropertyType>
}
