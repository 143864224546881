import type { Storage } from '@/modules/shared/domain/storage/storage'
import { asyncForEach } from '@/utils/array'
import type { AxiosInstance } from 'axios'
import type { Landlord } from '../domain/landlord'
import type { LandlordRepository } from '../domain/landlordRepository'
import { fromDto, type LandlordApiDto } from './apiDto'

export function apiLandlordRepositoryBuilder(
  apiService: AxiosInstance,
  apiV2Service: AxiosInstance,
  dateStorage: Storage<Date>,
  basicPlanVisibilityStorage: Storage<boolean>
): LandlordRepository {
  let cache: Nullable<Landlord> = null
  let cacheId: Nullable<string> = null

  return {
    async deleteDocument(userId: string, documentId: string) {
      cache = cacheId = null
      await apiService.delete(`/landlords/${userId}/documents/${documentId}`)
    },
    async get(userId: string) {
      if (userId === cacheId && cache !== null) {
        return cache
      }

      const dto = (await apiService.get(`/landlords/${userId}`)) as LandlordApiDto
      cache = fromDto(dto, dateStorage.get())
      cacheId = userId
      return cache
    },
    async update(landlord: Landlord) {
      cache = cacheId = null

      dateStorage.set(landlord.acceptedTermsAndConditionsAt)

      const filesToUpload = landlord.documents.id
        .filter(({ source }) => source !== null)
        .map(({ source }) => source as File)

      await asyncForEach(filesToUpload, async (file) => {
        const formData = new FormData()
        formData.append('file', file)

        await apiV2Service.post(`/onboarding/landlords/me/files/personal-id`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
      })
    },
    async getBasicPlanVisibility() {
      return Boolean(basicPlanVisibilityStorage.get())
    },
    async setBasicPlanVisibility(visible: boolean) {
      basicPlanVisibilityStorage.set(visible)
    }
  }
}
