<template>
  <div class="container row">
    <div class="col-3">
      <SignupStepper :step="1" />
    </div>
    <div class="col-lg-6 col-md-5 col-4">
      <div class="flex column gap-1">
        <div>
          <h2 class="mb-0">{{ t('signup.welcome') }}</h2>
          <Title path="signup.type.title" />
        </div>
        <BlobSelector
          data-test-id="landlordTypeSelector"
          :title="t('signup.type.aLandlord')"
          :subtitle="t('signup.type.aLandlordSub')"
          @click="ChooseType(UserType.Landlord)"
        />
        <BlobSelector
          data-test-id="tenantTypeSelector"
          :title="t('signup.type.aTenant')"
          :subtitle="t('signup.type.aTenantSub')"
          @click="ChooseType(UserType.Tenant)"
        />
      </div>
    </div>
  </div>
  <div class="fixed-bottom">
    <SignupStepper :step="1" mobile />
  </div>
</template>
<script lang="ts" setup>
import { BlobSelector, SignupStepper, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { isLandlord, UserType } from '@/modules/user/domain/user'
import { router } from '@/router'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

track(AnalyticsEvent.SignUpStart)

function ChooseType(type: UserType) {
  track(isLandlord(type) ? AnalyticsEvent.LandlordSignupSelectUserType : AnalyticsEvent.TenantSignupSelectUserType)
  router.push({ name: 'accountInformation', params: { type } })
}
</script>
