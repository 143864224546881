<template>
  <Stepper :title="t('signup.onboarding.title')" :mobile="mobile">
    <StepperItem :name="t('signup.type.step')" :done="step >= 1" :active="active === 'typeInformation'" />
    <StepperItem :name="t('signup.account.step')" :done="step >= 2" :active="active === 'accountInformation'" />
    <StepperItem :name="t('signup.personal.step')" :done="step >= 3" :active="active === 'personalInformation'" />
    <StepperItem
      v-if="showPropertyStep"
      :name="t('signup.property.step')"
      :done="step >= 4"
      :active="active === 'propertyInformation'"
    />
  </Stepper>
</template>
<script lang="ts" setup>
import { StepperItem, Stepper } from '@/components'
import { useI18n } from 'vue-i18n'
import { router } from '@/router'

defineProps<{ step: number; showPropertyStep?: boolean; mobile?: boolean }>()

const { t } = useI18n()

const active = router.currentRoute.value.name
</script>
