import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { object, string } from 'yup'
import { PricingPlan, type PropertyPaymentInformation } from '../domain/propertyPaymentInformation'

export function yupPropertyPaymentInformationValidatorBuilder(): Validator<PropertyPaymentInformation> {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyPaymentInformation>({
      plan: string().oneOf(Object.values(PricingPlan), 'validationError.required').nullable()
    })
  )
}
