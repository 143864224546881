import type { AuthService } from '@/modules/auth/domain/authService'
import type { UserRepository } from '../domain/userRepository'
import type { UserType } from '../domain/user'

export function signUpWithGoogleBuilder(userRepository: UserRepository, authService: AuthService) {
  return async (userType: UserType) => {
    const email = await authService.signInWithGoogle()
    try {
      const userId = await authService.getUserId()
      await userRepository.get(userId)
    } catch {
      await userRepository.create(userType, email)
    }
  }
}
