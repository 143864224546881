<template>
  <div class="date-picker-wrapper">
    <label>{{ label }}</label>
    <input
      :placeholder="placeholder"
      type="date"
      :value="modelValue"
      @input="onDateChange"
      class="input-field"
      :disabled="disabled"
    />
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps<{
  label: string
  modelValue: Nullable<string>
  error?: string
  placeholder?: string
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Nullable<string>): void
}>()

const { t } = useI18n()

function onDateChange(e: Event) {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}
</script>
<style scoped lang="scss">
.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .input-field {
    font-family: 'InstrumentSans';
    box-sizing: border-box;
    height: 2.75rem;
    padding: 0.75rem 0.5rem;
    border: 1px solid $lightBorder;
    border-radius: 6px;
    font-size: 1rem;

    &::placeholder {
      color: $grey300;
      font-weight: 300;
      font-size: 0.875rem;
    }
    &:disabled {
      background-color: $grey200;
    }
  }
}
</style>
