import { apiService, apiV2Service } from '@/modules/shared/infrastructure/api/axiosApiService'
import { apiPropertyRepositoryBuilder } from './apiPropertyRepository'
import { yupPropertyBasicInformationValidatorBuilder } from './yupPropertyBasicInformationValidator'
import { yupPropertyCoreInformationValidatorBuilder } from './yupPropertyCoreInformationValidator'
import { yupPropertyDocumentsValidatorBuilder } from './yupPropertyDocumentsValidator'
import { yupPropertyEquipmentInformationValidatorBuilder } from './yupPropertyEquipmentInformationValidator'
import { yupPropertyImagesValidatorBuilder } from './yupPropertyImagesValidator'
import { yupPropertyPaymentInformationValidatorBuilder } from './yupPropertyPaymentInformationValidator'

export const propertyRepository = apiPropertyRepositoryBuilder(apiService, apiV2Service)
export const propertyBasicInformationValidator = yupPropertyBasicInformationValidatorBuilder()
export const propertyCoreInformationValidator = yupPropertyCoreInformationValidatorBuilder()
export const propertyDocumentsValidator = yupPropertyDocumentsValidatorBuilder()
export const propertyEquipmentInformationValidator = yupPropertyEquipmentInformationValidatorBuilder()
export const propertyImagesValidator = yupPropertyImagesValidatorBuilder()
export const propertyPaymentInformationValidator = yupPropertyPaymentInformationValidatorBuilder()
