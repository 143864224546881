<template>
  <div>
    <RouterLink
      v-if="isLandlord(user.type)"
      class="link"
      :to="{ name: 'properties' }"
      :class="{ active: route.name === 'properties' }"
    >
      <img src="@/assets/images/icons/properties.svg" />
      <span>{{ t('properties.myProperties') }}</span>
    </RouterLink>
    <RouterLink
      v-if="isTenant(user.type)"
      class="link mb-05"
      :to="{ name: 'listings' }"
      :class="{ active: route.name === 'listings' }"
    >
      <img src="@/assets/images/icons/properties.svg" />
      <span>{{ t('listings.title') }}</span>
    </RouterLink>
    <RouterLink
      v-if="isTenant(user.type)"
      class="link"
      :to="{ name: 'applications' }"
      :class="{ active: route.name === 'applications' }"
    >
      <img src="@/assets/images/icons/applications.svg" />
      <span>{{ t('applications.myApplications') }}</span>
    </RouterLink>
  </div>
</template>
<script lang="ts" setup>
import { type User, isLandlord, isTenant } from '@/modules/user/domain/user'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

defineProps<{ user: User }>()

const { t } = useI18n()
const route = useRoute()
</script>
<style lang="sass" scoped>
.link
  text-align: left
  border-radius: 4rem
  text-decoration: none
  box-sizing: border-box
  font-weight: 500
  height: 3rem
  display: flex
  gap: 0.5rem
  align-items: center
  padding: 0rem 1rem

  &.active
    cursor: default
    background-color: white

  &:not(.active):hover
    cursor: pointer
    background-color: white
</style>
