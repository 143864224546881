<template>
  <div class="container">
    <h2>{{ t('profile.settings.title') }}</h2>
    <hr />
  </div>
  <div class="container narrow flex column gap-2 pt-0">
    <template v-if="user">
      <div class="flex align-center gap-1 mb-1">
        <Avatar :user="user"></Avatar>
        <h3>{{ user.name }}</h3>
      </div>
      <InputField disabled v-model="user.email" :label="t('profile.settings.email')" />
      <PhoneInputField v-model="user.phone" :error="errors['phone']" :label="t('profile.settings.phone')" />
      <a class="danger mb-1" @click="openDeleteAccountModal">{{ t('profile.settings.deleteAccount') }}</a>
      <Button variant="secondary" :loading="loading" @click="onSave">
        {{ t('profile.settings.save') }}
      </Button>
      <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">{{ errors['generic'] }}</p>
    </template>
    <DeleteAccountModal
      v-if="showDeleteAccountModal"
      :loading="deleting"
      @confirm="onDeleteAccount"
      @close="closeDeleteAccountModal"
    />
  </div>
</template>
<script setup lang="ts">
import { Avatar, Button, DeleteAccountModal, InputField, PhoneInputField } from '@/components'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { deleteCurrentUser, findCurrentUser, updateCurrentUser } from '@/modules/user/application'
import type { User } from '@/modules/user/domain/user'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const errors = ref<Record<string, string>>({})
const loading = ref(false)
const user = ref<Nullable<User>>(null)
const deleting = ref(false)
const showDeleteAccountModal = ref(false)

onMounted(async () => (user.value = await findCurrentUser()))

async function onSave() {
  if (!user.value) return

  loading.value = true
  errors.value = {}
  try {
    await updateCurrentUser(user.value)
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
  }
  loading.value = false
}

function openDeleteAccountModal() {
  showDeleteAccountModal.value = true
}

function closeDeleteAccountModal() {
  showDeleteAccountModal.value = false
}

async function onDeleteAccount() {
  deleting.value = true
  await deleteCurrentUser()
  location.reload()
  deleting.value = false
}
</script>
<style scoped lang="sass">
a
  align-self: flex-start
</style>
