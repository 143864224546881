<template>
  <div class="flex column gap-1">
    <div class="title preload"></div>
    <div class="input preload"></div>
    <div class="input preload"></div>
    <div class="input preload"></div>
  </div>
</template>
<script lang="ts" setup></script>
<style scoped lang="sass">
.title
  height: 2rem
  max-width: 10rem

.input
  height: 3rem

@keyframes preloadAnimation
  0%
    background: #EEE
  50%
    background: #FFF
  100%
    background: #EEE

.preload
  position: relative
  color: transparent !important
  cursor: default !important
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important
  border-color: #FFF !important

  *
    color: transparent

  &::after
    content: ""
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 0
    border-radius: 0.25rem
    animation-name: preloadAnimation
    animation-duration: 2s
    animation-iteration-count: infinite
    animation-timing-function: linear
</style>
