import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export enum ApplicationStatus {
  InProgress = 'in-progress',
  Filled = 'filled',
  Submitted = 'submitted',
  Closed = 'closed'
}

export interface Application {
  bedrooms: number
  city: string
  id: Uuid
  image: Nullable<string>
  price: number
  propertyId: string
  status: ApplicationStatus
  size: number
  street: string
}
