<template>
  <div id="typeform">
    <div :data-tf-live="formId"></div>
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'

defineProps<{ formId: string }>()

onMounted(() => {
  const script = document.createElement('script')
  script.src = import.meta.env.VITE_TYPEFORM_SOURCE as string
  document.getElementById('typeform')?.appendChild(script)
})
</script>
<style lang="sass" scoped>
#typeform
  padding-bottom: 4px
  background-color: #f5f3a0
</style>
