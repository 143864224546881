import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import type { ApiErrorResponse } from '../../domain/errors/httpError'
import HttpError from '../../domain/errors/httpError'
import GenericError from '../../domain/errors/genericError'

export const successInterceptor = (success: AxiosResponse) => {
  return success.data
}

export const errorInterceptor = () => async (error: AxiosError<ApiErrorResponse>) => {
  return await unhandledError(error)
}

export default (client: AxiosInstance) => {
  client.interceptors.response.use(successInterceptor, errorInterceptor())
}

async function unhandledError(error: AxiosError<ApiErrorResponse>) {
  if (error.response) {
    if (error.response.data) {
      return await Promise.reject(new HttpError(error.response.data, error.response.status))
    }
  }

  return await Promise.reject(error instanceof Error ? error : new GenericError(error))
}
