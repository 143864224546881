<template>
  <Modal v-if="opened" @close="onClose">
    <div class="flex align-center justify-center column gap-1 p-5 primary">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('common.congratulations') }}</h2>
      <p class="pl-2 pr-2">
        {{ t('applications.accountSuccessEmpty.main') }}
        <b>{{ t('applications.accountSuccessEmpty.bold') }}</b>
      </p>
      <img src="@/assets/images/curvedArrow.png" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-2">
      <Button @click="onClose">{{ t('common.continue') }}</Button>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'

import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const signupCompleted = router.currentRoute.value.query['accountSuccess'] !== undefined
const opened = ref(signupCompleted)

if (signupCompleted) {
  track(AnalyticsEvent.TenantSignupCompleted)
}

function onClose() {
  router.push({ name: 'listings' })
}
</script>
