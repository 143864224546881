/// <reference types="@types/google.maps" />

import { CountryState } from '../domain/propertyCoreInformation'

export async function findCountryState(street: string): Promise<CountryState> {
  const geocoder = new google.maps.Geocoder()
  const { results } = await geocoder.geocode({
    address: street,
    componentRestrictions: { country: 'es' }
  })

  if (!results.length) {
    throw new Error()
  }

  const state = results[0].address_components.find((component) =>
    component.types.includes('administrative_area_level_1')
  )

  if (!state) {
    throw new Error()
  }

  const spanishStateCode = `ES-${state.short_name}`
  if (!Object.values(CountryState).includes(spanishStateCode as CountryState)) {
    throw new Error()
  }

  return spanishStateCode as CountryState
}
