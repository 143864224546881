import { authService } from '@/modules/auth'
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios'

export const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const token: string | null = await authService.getUserToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
      config.headers.Accept = 'application/json'
    }
  }
  return config
}

export default (client: AxiosInstance) => {
  client.interceptors.request.use(requestInterceptor)
}
