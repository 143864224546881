<template>
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <FormSkeleton />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { FormSkeleton, PropertyStepper } from '@/components'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { hasLandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import { findPropertyById } from '@/modules/property/application'
import {
  hasBasicInformation,
  hasCoreInformation,
  hasEquipmentInformation,
  hasPropertyDocuments,
  hasPropertyImages,
  type Property
} from '@/modules/property/domain/property'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  if (!hasCoreInformation(property.value)) {
    router.replace({ name: 'coreInformation' })
  } else if (!hasBasicInformation(property.value)) {
    router.replace({ name: 'basicInformation' })
  } else if (!hasEquipmentInformation(property.value)) {
    router.replace({ name: 'equipmentInformation' })
  } else if (!hasPropertyImages(property.value)) {
    router.replace({ name: 'propertyPhotos' })
  } else if (!hasPropertyDocuments(property.value) || !hasLandlordDocuments(landlord.value)) {
    router.replace({ name: 'propertyDocuments' })
  } else {
    router.replace({ name: 'paymentInformation' })
  }
})
</script>
