<template>
  <Modal @close="emit('close')" mandatory>
    <div class="top flex align-center justify-center column gap-1 primary">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('profile.settings.deleteAccount') }}</h2>
      <p class="pl-2 pr-2">
        <b>{{ t('profile.settings.deleteAccountConfirmation.bold') }}</b>
        {{ t('profile.settings.deleteAccountConfirmation.description') }}
      </p>
      <img src="@/assets/images/curvedArrow.png" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-2">
      <Button :loading="loading" @click="emit('close')">
        {{ t('profile.settings.deleteAccountConfirmation.cancel') }}
      </Button>
      <a @click="emit('confirm')">{{ t('profile.settings.deleteAccountConfirmation.confirm') }}</a>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { useI18n } from 'vue-i18n'

defineProps<{ loading: boolean }>()
const emit = defineEmits(['confirm', 'close'])

const { t } = useI18n()
</script>
<style lang="sass" scoped>
.top
  padding: 5rem

  @media screen and (max-width: 768px)
    padding: 4rem 2rem
</style>
