import type { PropertyCoreInformation } from '../domain/propertyCoreInformation'
import type { PropertyRepository } from '../domain/propertyRepository'

export function updateCoreInformationBuilder(
  propertyRepository: PropertyRepository,
  validator: Validator<PropertyCoreInformation>
) {
  return async (id: string, primitive: Primitive<PropertyCoreInformation>) => {
    const coreInformation = validator(primitive)
    await propertyRepository.updateCoreInformation(id, coreInformation)
  }
}
