import { type SendPasswordRecoveryEmailForm } from '../domain/sendPasswordRecoveryEmailForm'
import type { UserRepository } from '../domain/userRepository'

export function sendPasswordRecoveryEmailBuilder(
  userRepository: UserRepository,
  validator: Validator<SendPasswordRecoveryEmailForm>
) {
  return async (primitive: Primitive<SendPasswordRecoveryEmailForm>) => {
    const form = validator(primitive)
    await userRepository.sendPasswordRecoveryEmail(form.email)
  }
}
