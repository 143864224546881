<template>
  <div class="container">
    <h2>{{ t('profile.help.title') }}</h2>
    <hr />
  </div>
  <div class="container narrow flex column gap-2 pt-0">
    <p class="title">
      {{ t('profile.help.description') }}
    </p>
    <a :href="link" target="_blank">
      <Button variant="secondary">
        {{ t('profile.help.contact') }}
      </Button>
    </a>
  </div>
</template>
<script setup lang="ts">
import { Button } from '@/components'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const link = import.meta.env.VITE_CONTACT_WHATSAPP
</script>
<style scoped lang="sass">
a, .button
  width: 100%
</style>
