import { enMessages } from '@/i18n/english'
import { esMessages } from '@/i18n/spanish'
import { createI18n } from 'vue-i18n'

export type Language = 'en' | 'es'

function getSystemLanguage(): Language {
  const systemLang = navigator.language.split('-')[0]
  return systemLang === 'en' || systemLang === 'es' ? systemLang : 'es'
}

export const i18n = createI18n({
  locale: localStorage.getItem('lang') ?? getSystemLanguage(),
  fallbackLocale: 'es',
  legacy: false,
  messages: {
    en: enMessages,
    es: esMessages
  }
})
