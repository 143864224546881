<template>
  <div class="container row">
    <div class="col-3">
      <SignupStepper v-if="user && !isPartner(user.type)" :step="3" :showPropertyStep="isLandlord(user.type)" />
    </div>
    <div class="col-lg-6 col-md-5 col-4">
      <div class="flex column gap-1">
        <div>
          <h2>{{ t('signup.welcome') }}</h2>
          <Title v-if="user" :path="`signup.personal.${user.type}.title`" />
        </div>
        <template v-if="user">
          <InputField
            v-model="name"
            :error="errors['name']"
            :label="t('forms.name')"
            :placeholder="t('forms.namePlaceholder')"
            :trackEnterEvent="enterNameEvents[user.type]"
            data-test-id="nameInput"
          />
          <InputField
            v-model="surname"
            :error="errors['surname']"
            :label="t('forms.surname')"
            :placeholder="t('forms.surnamePlaceholder')"
            :trackEnterEvent="enterSurnameEvents[user.type]"
            data-test-id="surnameInput"
          />
          <PhoneInputField
            v-model="phone"
            :error="errors['phone']"
            :label="t('forms.phone')"
            :placeholder="t('forms.phonePlaceholder')"
            :trackEnterEvent="enterPhoneEvents[user.type]"
          />
          <p v-if="errors['generic']" class="error-message center" data-test-id="genericError">
            {{ errors['generic'] }}
          </p>
          <Button
            class="mt-1"
            :variant="isPartner(user.type) ? 'primary' : 'secondary'"
            :loading="loading"
            @click="onAddAccountInformation"
            data-test-id="addAccountInformationButton"
          >
            {{ t(`signup.personal.${user.type}.save`) }}
          </Button>
        </template>
        <FormSkeleton v-else />
      </div>
    </div>
  </div>
  <div class="fixed-bottom" v-if="user && !isPartner(user.type)">
    <SignupStepper :step="3" mobile />
  </div>
</template>
<script lang="ts" setup>
import { Button, FormSkeleton, InputField, PhoneInputField, SignupStepper, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { addProperty } from '@/modules/property/application'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { findCurrentUser, updateCurrentUser } from '@/modules/user/application'
import { isLandlord, isPartner, isTenant, UserType, type User } from '@/modules/user/domain/user'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const name = ref<Nullable<string>>(null)
const surname = ref<Nullable<string>>(null)
const phone = ref<Nullable<string>>(null)
const loading = ref(false)
const errors = ref<Record<string, string>>({})
const user = ref<Nullable<User>>(null)

const enterNameEvents: Partial<Record<UserType, AnalyticsEvent>> = {
  [UserType.Landlord]: AnalyticsEvent.LandlordSignupEnterName,
  [UserType.Tenant]: AnalyticsEvent.TenantSignupEnterName
}

const enterSurnameEvents: Partial<Record<UserType, AnalyticsEvent>> = {
  [UserType.Landlord]: AnalyticsEvent.LandlordSignupEnterSurname,
  [UserType.Tenant]: AnalyticsEvent.TenantSignupEnterSurname
}

const enterPhoneEvents: Partial<Record<UserType, AnalyticsEvent>> = {
  [UserType.Landlord]: AnalyticsEvent.LandlordSignupEnterPhone,
  [UserType.Tenant]: AnalyticsEvent.TenantSignupEnterPhone
}

onMounted(async () => {
  user.value = await findCurrentUser()
  name.value = user.value?.name
  surname.value = user.value?.surname
  phone.value = user.value?.phone

  track(
    isLandlord(user.value.type)
      ? AnalyticsEvent.LandlordSignupStartPersonalData
      : AnalyticsEvent.TenantSignupStartPersonalData
  )
})

async function finishLandlordSignup() {
  const { id } = await addProperty()
  router.push({ name: 'propertyInformation', params: { id } })
}

async function finishTenantSignup() {
  router.push({ name: 'applications', query: { accountSuccess: 'true' } })
}

async function onAddAccountInformation() {
  if (!user.value) return

  loading.value = true
  errors.value = {}

  try {
    await updateCurrentUser({ name: name.value, phone: phone.value, type: user.value.type, surname: surname.value })
    if (isLandlord(user.value.type)) {
      track(AnalyticsEvent.LandlordSignupSubmitPersonalData)
      await finishLandlordSignup()
    } else if (isTenant(user.value.type)) {
      track(AnalyticsEvent.TenantSignupSubmitPersonalData)
      await finishTenantSignup()
    }
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    loading.value = false
  }
}
</script>
