<template>
  <ApplicationCompletedModal v-if="showDocumentationCompletedModal" />
  <TenantPartialSaveModal
    v-if="showSaveAndExitConfirmationModal"
    @confirm="saveAndExit"
    @close="showSaveAndExitConfirmationModal = false"
  />
  <div class="container">
    <div class="flex gap-05 align-center mb-3">
      <RouterLink :to="{ name: 'applications' }">
        <IconButton :icon="arrowBackIcon" />
      </RouterLink>
      <h1>{{ t('application.documentation.back') }}</h1>
    </div>
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <DocumentationHint :mobile="false" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <div class="flex column gap-2">
          <div>
            <h2>{{ t('application.documentation.title') }}</h2>
            <p class="mt-1">{{ t('application.documentation.description') }}</p>
            <DocumentationHint :mobile="true" class="mt-1" />
          </div>
          <template v-if="tenants.length">
            <template v-for="(_, index) in tenants">
              <TenantCard
                ref="tenantCards"
                v-model="tenants[index]"
                :applicationId="id"
                :index="index"
                @deleted="onTenantDeleted(index)"
              />
            </template>
            <Button :disabled="!isApplicationCompleted" @click="onAddTenant" data-test-id="addTenantButton">
              {{ t('tenant.add') }}
            </Button>
          </template>
          <CardSkeleton v-else />
        </div>
      </div>
    </div>
  </div>
  <Teleport v-if="tenants.length" to="#userLayoutFooter" :disabled="showDocumentationCompletedModal">
    <DocumentationFooter :canSend="isApplicationCompleted" :loading="loading" @exit="onSaveAndExit" @send="onSend" />
  </Teleport>
</template>
<script lang="ts" setup>
import arrowBackIcon from '@/assets/images/icons/arrow_back.svg'
import {
  ApplicationCompletedModal,
  Button,
  CardSkeleton,
  DocumentationFooter,
  DocumentationHint,
  IconButton,
  TenantCard,
  TenantPartialSaveModal
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findApplicationStatus, submitApplication } from '@/modules/application/application'
import { ApplicationStatus } from '@/modules/application/domain/application'
import { findAllTenants } from '@/modules/tenant/application'
import { isTenantCompleted, type Tenant } from '@/modules/tenant/domain/tenant'
import { TenantDocumentType } from '@/modules/tenant/domain/tenantDocuments'
import { router } from '@/router'
import { asyncForEach } from '@/utils/array'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const tenants = ref<Tenant[]>([])
const loading = ref(true)
const tenantCards = ref<(typeof TenantCard)[]>([])
const showSaveAndExitConfirmationModal = ref(false)
const showDocumentationCompletedModal = ref(false)
const isApplicationCompleted = computed(() => tenants.value.every(isTenantCompleted))
const hasPendingChanges = computed(() => tenantCards.value.some((card) => card.checkPendingChanges()))
let removeRouterListener: () => void

onMounted(async () => {
  const applicationStatus = await findApplicationStatus(id)
  tenants.value = await findAllTenants(id)
  loading.value = false

  if (applicationStatus === ApplicationStatus.Submitted) {
    router.push({ name: 'applicationDetails' })
  } else {
    track(AnalyticsEvent.TenantApplicationDocuments)
  }
})

onBeforeMount(() => {
  window.addEventListener('beforeunload', onBeforeUnload)
  removeRouterListener = router.beforeEach((to, from, next) => {
    const allowNavigation = !hasPendingChanges.value || window.confirm(t('tenant.exitWithoutSavingConfirmation'))
    next(allowNavigation)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', onBeforeUnload)
  removeRouterListener()
})

async function onAddTenant() {
  track(AnalyticsEvent.TenantApplicationAddTenant)
  tenants.value.push({
    id: null,
    name: null,
    surname: null,
    email: null,
    phone: null,
    employmentStatus: null,
    documents: {
      [TenantDocumentType.EmploymentContract]: [],
      [TenantDocumentType.Id]: [],
      [TenantDocumentType.LastForm100]: [],
      [TenantDocumentType.LastForm130]: [],
      [TenantDocumentType.Payslip]: [],
      [TenantDocumentType.PensionRevaluationCertificate]: []
    }
  })
}

async function onTenantDeleted(index: number) {
  tenants.value.splice(index, 1)
}

async function onSend() {
  track(AnalyticsEvent.TenantApplicationSendDocuments)
  loading.value = true
  try {
    await asyncForEach(tenantCards.value, (tenantCard) => tenantCard.save())
    await submitApplication(id)
    track(AnalyticsEvent.TenantApplicationCompleted)
    showDocumentationCompletedModal.value = true
  } catch {
    showSaveAndExitConfirmationModal.value = false
    loading.value = false
  }
}

async function onSaveAndExit() {
  if (tenants.value.length > 1) {
    showSaveAndExitConfirmationModal.value = true
  } else {
    await saveAndExit()
  }
}

async function saveAndExit() {
  track(AnalyticsEvent.TenantApplicationSaveDocuments)
  loading.value = true
  try {
    await asyncForEach(tenantCards.value, (tenantCard) => tenantCard.save(true))
    router.push({ name: 'applications', query: { applicationUpdated: 'true' } })
  } catch {
    showSaveAndExitConfirmationModal.value = false
    loading.value = false
  }
}

function onBeforeUnload(event: BeforeUnloadEvent) {
  if (hasPendingChanges.value) {
    event.preventDefault()
    event.returnValue = ''
    return ''
  }
}
</script>
