<template>
  <div class="container narrow">
    <form class="flex column gap-1" @submit="onSignIn">
      <div>
        <h2>{{ t('auth.welcome') }}</h2>
        <h2>
          <b>{{ t('auth.signIn') }}</b>
        </h2>
      </div>
      <SocialSignIn navigateOnSuccess="home" />
      <OrSeparator />
      <InputField
        v-model="email"
        :error="errors['email']"
        :label="t('forms.email')"
        :placeholder="t('forms.emailPlaceholder')"
        type="email"
        data-test-id="emailInput"
      />
      <div>
        <InputField
          v-model="password"
          :error="errors['password']"
          :label="t('forms.password')"
          :placeholder="t('forms.passwordPlaceholder')"
          type="password"
          data-test-id="passwordInput"
        />
        <RouterLink :to="{ name: 'recoverPassword' }" class="recover-link">
          {{ t('signin.recoverPassword.link') }}
        </RouterLink>
      </div>
      <p v-if="errors['auth']" class="error-message center" data-test-id="authenticationError">
        {{ t(errors['auth']) }}
      </p>
      <Button class="mt-2" :loading="loading" submit data-test-id="signInButton">
        {{ t('auth.signIn') }}
      </Button>
      <div class="link-wrapper">
        {{ t('auth.signUpPrompt') }}
        <RouterLink :to="{ name: 'signUp' }">
          {{ t('auth.signUpLink') }}
        </RouterLink>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { Button, InputField, SocialSignIn, OrSeparator } from '@/components'
import { ref } from 'vue'
import { signIn } from '@/modules/user/application'
import { router } from '@/router'
import { FirebaseError } from 'firebase/app'
import { useI18n } from 'vue-i18n'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'

const { t } = useI18n()

const email = ref<string>('')
const password = ref<string>('')
const loading = ref(false)
const errors = ref<Record<string, string>>({})

async function onSignIn(event: Event) {
  event.preventDefault()
  loading.value = true
  errors.value = {}

  try {
    await signIn({ email: email.value, password: password.value })
    router.push({ name: 'properties' })
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof FirebaseError) {
      errors.value = { auth: 'authenticationError.invalidCredentials' }
    }
    loading.value = false
  }
}
</script>
<style lang="sass" scoped>
.recover-link
  display: inline-block
  float: right
  margin-top: 0.5rem

.link-wrapper
  font-size: 0.875rem
</style>
