import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { number, object, string } from 'yup'
import { AvailabilityType, type PropertyCoreInformation } from '../domain/propertyCoreInformation'

export function yupPropertyCoreInformationValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyCoreInformation>({
      availabilityDate: string()
        .matches(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/, 'validatorError.invalidDate')
        .nullable(),
      availabilityType: string().oneOf(Object.values(AvailabilityType), 'validationError.required').nullable(),
      builtSize: number().nullable(),
      catastralReference: string().nullable(),
      city: string().nullable(),
      floor: string().nullable(),
      state: string().nullable(),
      street: string().nullable(),
      rent: number().nullable(),
      usableSize: number().nullable(),
      zip: string().nullable()
    })
  )
}
