import type { Application, ApplicationStatus } from '../domain/application'

export interface ApplicationApiDto {
  id: string
  propertyId: string
  status: 'in-progress' | 'filled' | 'submitted' | 'closed'
}

export interface PropertyImageDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png'
}

export interface PropertyApiDto {
  id: string
  address: {
    street: string
    city: string
  }
  features: {
    numberOfBedrooms: number
  }
  size: {
    built: number
  }
  rent: {
    amount: number
  }
  publishedOn: { id: string; name: 'idealista' }[]
}

function fromImageDtos(propertyId: string, dtos: PropertyImageDto[]): Nullable<string> {
  if (dtos.length === 0) return null

  const extensions = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
  }
  const firstImage = dtos[0]
  return `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/properties/${propertyId}/images/${firstImage.id}.${extensions[firstImage.mimeType]}`
}

export function fromDto(
  dto: ApplicationApiDto,
  propertyDto: PropertyApiDto,
  imageDtos: PropertyImageDto[]
): Application {
  return {
    bedrooms: propertyDto.features.numberOfBedrooms,
    city: propertyDto.address.city,
    id: dto.id,
    image: fromImageDtos(propertyDto.id, imageDtos),
    price: propertyDto.rent.amount,
    propertyId: dto.propertyId,
    status: dto.status as ApplicationStatus,
    size: propertyDto.size.built,
    street: propertyDto.address.street
  }
}
