import { generateUuid, type Uuid } from '@/modules/shared/domain/uuid/uuid'
import type { TenantRepository } from '../domain/tenantRepository'
import type { UpdateTenantForm } from '../domain/updateTenantForm'

export function createBuilder(tenantRepository: TenantRepository, validator: Validator<UpdateTenantForm>) {
  return async (primitive: Primitive<UpdateTenantForm>): Promise<Uuid> => {
    const form = validator(primitive)
    const tenantId = generateUuid()
    await tenantRepository.create(tenantId, form)
    return tenantId
  }
}
