<template>
  <div class="file-input">
    <div class="files" :class="{ empty: modelValue.length === 0 }">
      <div
        class="drag-zone"
        :class="{ active: isDragging }"
        @dragenter="onDragStart"
        @dragover="onDragStart"
        @dragleave="onDragCancel"
        @dragend="onDragCancel"
        @drop="onDragFinished"
        @click="onBrowseFiles"
      >
        <img v-if="type === 'image'" src="@/assets/images/icons/add_photo.svg" alt="" />
        <img v-else src="@/assets/images/icons/add_notes.svg" alt="" />
      </div>
      <div class="file" v-for="(document, index) in modelValue" :key="index">
        <template v-if="document.uri">
          <img v-if="isDocumentImage(document)" :src="document.uri" :alt="document.name" />
          <a v-else :href="document.uri" target="_blank">{{ document.name }}</a>
        </template>
        <p v-else>{{ document.name }}</p>
        <div class="delete" @click="emit('delete', document)">
          <img src="@/assets/images/icons/delete.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="instructions">
      <p>
        <span>{{ type === 'image' ? t('forms.fileInputImage') : t('forms.fileInputFile') }}&nbsp;</span>
        <input type="file" name="files[]" :id="`${name}Input`" :accept="mimeTypes" multiple @change="onInputChange" />
        <label :for="`${name}Input`">
          <strong>{{ t('forms.fileInputBrowse') }}</strong>
        </label>
      </p>
      <p>
        {{ maxFiles }} {{ t('forms.fileInputMax') }}. {{ t('forms.fileInputMaxSize', [maxFileSizeMB]) }}.
        <span v-if="instructions">{{ instructions }}</span>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import type { Document } from '@/modules/shared/domain/document/document'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const maxFileSizeMB = 20
const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024

const props = defineProps<{
  modelValue: Document[]
  name: string
  type: 'image' | 'file'
  maxFiles: number
  mimeTypes: string
  instructions?: string
  trackSelectEvent?: AnalyticsEvent
  trackInvalidFormatEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: Document[]): void
  (e: 'delete', value: Document): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const isDragging = ref(false)

function addFiles(fileList: FileList) {
  const documentsToAdd: Document[] = []

  for (const file of fileList) {
    if (props.mimeTypes.includes(file.type) && file.size < maxFileSizeBytes) {
      documentsToAdd.push({
        id: null,
        name: file.name,
        source: file,
        uri: isFileImage(file) ? URL.createObjectURL(file) : null
      })
    } else if (props.trackInvalidFormatEvent) {
      track(props.trackInvalidFormatEvent)
    }
  }

  if (documentsToAdd.length > 0) {
    emit('update:modelValue', [...props.modelValue, ...documentsToAdd])
    if (props.trackSelectEvent) {
      track(props.trackSelectEvent)
    }
  }
}

function isFileImage(file: File): boolean {
  return ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
}

function isDocumentImage({ name }: Document): boolean {
  return ['.png', '.jpg', '.jpeg'].some((extension) => name.toLowerCase().endsWith(extension))
}

function onDragStart(event: DragEvent) {
  isDragging.value = true
  event.preventDefault()
  event.stopPropagation()
}

function onDragCancel(event: DragEvent) {
  isDragging.value = false
  event.preventDefault()
  event.stopPropagation()
}

function onDragFinished(event: DragEvent) {
  isDragging.value = false
  event.preventDefault()
  event.stopPropagation()

  if (event.dataTransfer) {
    addFiles(event.dataTransfer.files)
  }
}

function onInputChange(event: Event) {
  const input = event.target as HTMLInputElement
  const fileList = input.files
  if (fileList) {
    addFiles(fileList)
  }
  input.value = ''
}

function onBrowseFiles() {
  document.getElementById(`${props.name}Input`)?.click()
}
</script>
<style lang="sass" scoped>
$itemSize: 7.2rem
$itemRadius: 1rem

.file-input
  .instructions
    text-align: center

    p
      font-size: 0.875rem
      line-height: 1rem

  input
    display: none

  label:hover
    cursor: pointer
    text-decoration: underline

  .files
    display: flex
    flex-wrap: wrap
    gap: 0.5rem
    margin-bottom: 1.5rem

    .drag-zone
      height: $itemSize
      width: $itemSize
      background-color: #f0f0f0
      border-radius: $itemRadius
      border: 2px dashed #d4d4d4
      display: flex
      align-items: center
      justify-content: center
      box-sizing: border-box
      cursor: pointer

      &.active
        border-color: #0b0b0b

      img
        transition: transform 0.2s

      &:not(.active):hover
        img
          transform: scale(1.1)

    .file
      border-radius: $itemRadius
      height: $itemSize
      width: $itemSize
      overflow: hidden
      box-sizing: border-box
      border: 1px solid #d4d4d4
      display: flex
      justify-content: center
      align-items: center
      position: relative
      background-color: #f0f0f0

      p
        padding: 0.5rem
        font-size: 0.75rem
        color: #9F9F9F
        text-align: center
        word-break: break-all

      img
        max-width: 100%
        max-height: 100%

      .delete
        width: 2rem
        height: 2rem
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        top: 0.5rem
        right: 0.5rem
        border-radius: 1rem
        background-color: white
        cursor: pointer
        opacity: 0.75

        &:hover
          opacity: 1

    &.empty
      .drag-zone
        width: 100%
        flex-grow: 1
</style>
