import { daysBetween } from '@/utils/date'
import type { LandlordDocuments } from './landlordDocuments'

export interface Landlord {
  acceptedTermsAndConditionsAt: Date
  createdAt: Date
  documents: LandlordDocuments
}

export function mustAcceptTheTermsAndConditions(landlord: Landlord): boolean {
  return daysBetween(landlord.acceptedTermsAndConditionsAt, new Date()) > 1
}
