import type { Landlord } from '../domain/landlord'
import type { LandlordDocuments } from '../domain/landlordDocuments'
import type { LandlordRepository } from '../domain/landlordRepository'

export function updateBuilder(landlordRepository: LandlordRepository, validator: Validator<LandlordDocuments>) {
  return async (landlord: Landlord) => {
    validator(landlord.documents)
    await landlordRepository.update(landlord)
  }
}
