import { object, string } from 'yup'
import type { SendPasswordRecoveryEmailForm } from '../domain/sendPasswordRecoveryEmailForm'
import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'

export function yupSendPasswordRecoveryEmailFormValidatorBuilder(): Validator<SendPasswordRecoveryEmailForm> {
  return yupGenericSchemaValidatorBuilder(
    object<SendPasswordRecoveryEmailForm>({
      email: string()
        .test(
          'is-valid-email',
          'validationError.formatEmail',
          (value) => !value || /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/.test(value)
        )
        .required('validationError.requiredEmail')
    })
  )
}
