import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { object, string } from 'yup'
import { TenantEmploymentStatus } from '../domain/tenantEmploymentStatus'
import type { UpdateTenantForm } from '../domain/updateTenantForm'

export function yupUpdateTenantFormValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<UpdateTenantForm>({
      email: string()
        .required('validationError.requiredEmail')
        .test(
          'is-valid-email',
          'validationError.formatEmail',
          (value) => !value || /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/.test(value)
        ),
      employmentStatus: string().nullable().oneOf(Object.values(TenantEmploymentStatus), 'validationError.required'),
      phone: string()
        .required('validationError.requiredPhone')
        .test(
          'is-valid-phone',
          'validationError.formatPhone',
          (value) => !value || /^(?:\+34|0034|34)?[6789\*](\d|\*){8}$/.test(value)
        ),
      name: string().required('validationError.requiredName'),
      surname: string().required('validationError.requiredSurname')
    })
  )
}
