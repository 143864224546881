<template>
  <div class="main">
    <div class="container">
      <h1 class="mb-2">{{ t('listings.title') }}</h1>
      <hr />
      <div class="flex gap-1 wrap mt-2">
        <template v-if="listings">
          <ImageCard v-for="listing in listings" :source="listing.imageUrl">
            <div class="description">
              <div>
                <template v-if="listing.idealistaUrl">
                  <div class="flex justify-between align-center">
                    <p class="title">{{ t('listings.idealistaLink') }}</p>
                    <a
                      class="icon"
                      target="_blank"
                      :href="listing.idealistaUrl"
                      @click="track(AnalyticsEvent.TenantPropertiesSelectIdealista)"
                    >
                      <IconButton :icon="arrowOutwardIcon" border small data-test-id="propertyDetails" />
                    </a>
                  </div>
                  <hr />
                </template>
                <p class="title mt-1 mb-05">{{ listing.street }}, {{ listing.city }}</p>
                <p class="light">
                  {{ listing.builtSize }} m² | {{ listing.bedrooms }} {{ t('common.roomsAbbreviation') }}
                </p>
                <p class="mb-1">{{ listing.price }} €/{{ t('common.month') }}</p>
              </div>
              <div v-if="appliedPropertiesIds.includes(listing.propertyId)">
                <hr />
                <div class="flex justify-between align-center">
                  <span>{{ t('listings.applied') }}</span>
                  <img src="@/assets/images/icons/check_circle_filled_green.svg" />
                </div>
              </div>
              <Button v-else class="mt-1" @click="onRequest(listing)">
                {{ t('listings.request') }}
              </Button>
            </div>
          </ImageCard>
        </template>
        <template v-else>
          <ImageCardSkeleton />
          <ImageCardSkeleton />
          <ImageCardSkeleton />
        </template>
      </div>
    </div>
    <ApplicationInstructions />
  </div>
</template>
<script setup lang="ts">
import arrowOutwardIcon from '@/assets/images/icons/arrow_outward.svg'
import { ApplicationInstructions, Button, IconButton, ImageCard, ImageCardSkeleton } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication, findAllApplications } from '@/modules/application/application'
import { findAllListings } from '@/modules/listing/application'
import type { Listing } from '@/modules/listing/domain/listing'
import { router } from '@/router'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const appliedPropertiesIds = ref<string[]>([])
const listings = ref<Nullable<Listing[]>>(null)

track(AnalyticsEvent.TenantPropertiesPropertiesList)

onMounted(async () => {
  const applications = await findAllApplications()
  appliedPropertiesIds.value = applications.map(({ propertyId }) => propertyId)
  listings.value = await findAllListings()
})

async function onRequest(listing: Listing) {
  track(AnalyticsEvent.TenantPropertiesApply)
  const id = await createApplication(listing.propertyId)
  router.push({ name: 'tenantsInformation', params: { id } })
}
</script>
<style scoped lang="sass">
@import "@/assets/styles/responsive.sass"

@include lg
  .main
    display: flex
    justify-content: space-between
    align-items: flex-start

    .container
      flex-grow: 1

.description
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%

hr
  margin: 0.75rem 0rem
</style>
