import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import type { TenantRepository } from '../domain/tenantRepository'
import type { UpdateTenantForm } from '../domain/updateTenantForm'

export function updateBuilder(tenantRepository: TenantRepository, validator: Validator<UpdateTenantForm>) {
  return async (tenantId: Uuid, primitive: Primitive<UpdateTenantForm>) => {
    const form = validator(primitive)
    await tenantRepository.update(tenantId, form)
  }
}
