import { yupGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/yup/yupGenericSchemaValidator'
import { boolean, number, object, string } from 'yup'
import { PropertyCondition, PropertyType, type PropertyBasicInformation } from '../domain/propertyBasicInformation'

export function yupPropertyBasicInformationValidatorBuilder() {
  return yupGenericSchemaValidatorBuilder(
    object<PropertyBasicInformation>({
      bathrooms: number().nullable(),
      bedrooms: number().nullable(),
      pets: boolean().nullable(),
      propertyCondition: string().oneOf(Object.values(PropertyCondition), 'validationError.required').nullable(),
      propertyType: string().oneOf(Object.values(PropertyType), 'validationError.required').nullable()
    })
  )
}
