import type { PropertyPaymentInformation } from '../domain/propertyPaymentInformation'
import type { PropertyRepository } from '../domain/propertyRepository'

export function updatePaymentInformationBuilder(
  propertyRepository: PropertyRepository,
  validator: Validator<PropertyPaymentInformation>
) {
  return async (id: string, primitive: Primitive<PropertyPaymentInformation>) => {
    const paymentInformation = validator(primitive)
    await propertyRepository.updatePaymentInformation(id, paymentInformation)
  }
}
