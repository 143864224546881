import { applicationRepository } from '../infrastructure'
import { createBuilder } from './create'
import { findAllBuilder } from './findAll'
import { findStatusBuilder } from './findStatus'
import { linkBuilder } from './link'
import { submitBuilder } from './submit'
import { unlinkBuilder } from './unlink'

export const createApplication = createBuilder(applicationRepository)
export const findAllApplications = findAllBuilder(applicationRepository)
export const findApplicationStatus = findStatusBuilder(applicationRepository)
export const linkTenantToApplication = linkBuilder(applicationRepository)
export const unlinkTenantFromApplication = unlinkBuilder(applicationRepository)
export const submitApplication = submitBuilder(applicationRepository)
