<template>
  <div class="wrapper">
    <label class="switch">
      <input type="checkbox" :checked="modelValue ?? false" @input="onInput" :disabled="disabled" />
      <span class="slider round" :class="{ disabled }"></span>
    </label>
    <p v-if="label">{{ label }}</p>
  </div>
</template>
<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import type { InputHTMLAttributes } from 'vue'

const props = defineProps<{
  modelValue: Nullable<boolean>
  label?: string
  disabled?: boolean
  trackSelectEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const { track } = useAnalytics()

function onInput(event: Event) {
  const target = event.target as InputHTMLAttributes
  emit('update:modelValue', Boolean(target.checked))
  if (props.trackSelectEvent) {
    track(props.trackSelectEvent)
  }
}
</script>
<style scoped lang="sass">
.wrapper
  display: flex
  gap: 0.5rem
  align-items: center

  p
    font-size: 0.875rem

.switch
  position: relative
  display: inline-block
  width: 3.25rem
  height: 2rem
  flex-shrink: 0

.switch input
  opacity: 0
  width: 0
  height: 0

.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #f0f0f0
  border-radius: 1rem
  border: 1px solid #d4d4d4

  &.disabled
    opacity: 0.75
    cursor: default

  &:not(.disabled):hover
    border-color: #0B0B0B

  &:before
    position: absolute
    content: ''
    height: 1rem
    width: 1rem
    border-radius: 0.5rem
    left: 0.5rem
    bottom: 0.5rem
    background-color: #d4d4d4
    transition: transform 0.2s

input:checked + .slider
  background-color: #f6f3a0

input:checked + .slider:before
  background-color: white

input:checked + .slider:before
  transform: translateX(1.25rem)
</style>
