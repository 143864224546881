import type { Document } from '@/modules/shared/domain/document/document'
import { TenantEmploymentStatus } from './tenantEmploymentStatus'

export enum TenantDocumentType {
  EmploymentContract = 'employmentContract',
  Id = 'id',
  LastForm100 = 'lastForm100',
  LastForm130 = 'lastForm130',
  Payslip = 'payslip',
  PensionRevaluationCertificate = 'pensionRevaluationCertificate'
}

export type TenantDocuments = Record<TenantDocumentType, Document[]>

export const employmentStatusDocumentTypes: Record<TenantEmploymentStatus, TenantDocumentType[]> = {
  [TenantEmploymentStatus.Employed]: [
    TenantDocumentType.Id,
    TenantDocumentType.EmploymentContract,
    TenantDocumentType.Payslip
  ],
  [TenantEmploymentStatus.SelfEmployed]: [
    TenantDocumentType.Id,
    TenantDocumentType.LastForm100,
    TenantDocumentType.LastForm130
  ],
  [TenantEmploymentStatus.Retired]: [TenantDocumentType.Id, TenantDocumentType.PensionRevaluationCertificate]
}
