import type { Listing } from '../domain/listing'

export interface ListingDto {
  id: string
  address: {
    street: string
    city: string
  }
  features: {
    numberOfBedrooms: number
  }
  size: {
    built: number
  }
  rent: {
    amount: number
  }
  publishedOn: { id: string; name: 'idealista' }[]
}

export interface ListingImageDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png'
}

function fromImageDtos(propertyId: string, dtos: ListingImageDto[]): Nullable<string> {
  if (dtos.length === 0) return null

  const extensions = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
  }
  const firstImage = dtos[0]
  return `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/properties/${propertyId}/images/${firstImage.id}.${extensions[firstImage.mimeType]}`
}

export function fromListingDto(dto: ListingDto, imageDtos: ListingImageDto[]): Listing {
  const idealistaId = dto.publishedOn.find((source) => source.name === 'idealista')?.id

  return {
    bedrooms: dto.features.numberOfBedrooms,
    builtSize: dto.size.built,
    city: dto.address.city,
    idealistaUrl: idealistaId ? `${import.meta.env.VITE_IDEALISTA_BASE_URL}/${idealistaId}` : null,
    imageUrl: fromImageDtos(dto.id, imageDtos),
    price: dto.rent.amount,
    propertyId: dto.id,
    street: dto.address.street
  }
}
