import { authService } from '@/modules/auth'
import { landlordDocumentsValidator, landlordRepository } from '../infrastructure'
import { deleteDocumentBuilder } from './deleteDocument'
import { findBasicPlanVisibilityBuilder } from './findBasicPlanVisibility'
import { findCurrentBuilder } from './findCurrent'
import { setBasicPlanVisibilityBuilder } from './setBasicPlanVisibility'
import { updateBuilder } from './update'

export const deleteCurrentLandlordDocument = deleteDocumentBuilder(authService, landlordRepository)
export const findCurrentLandlord = findCurrentBuilder(authService, landlordRepository)
export const updateCurrentLandlord = updateBuilder(landlordRepository, landlordDocumentsValidator)
export const findCurrentLandlordBasicPlanVisibility = findBasicPlanVisibilityBuilder(landlordRepository)
export const setCurrentLandlordBasicPlanVisibility = setBasicPlanVisibilityBuilder(landlordRepository)
