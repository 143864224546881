<template>
  <div class="radio-button">
    <label v-for="option in options">
      {{ t(option.label) }}
      <input
        type="radio"
        :checked="modelValue === option.value"
        :class="{ checked: modelValue === option.value }"
        :name="name"
        @change="emit('update:modelValue', option.value)"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

defineProps<{
  name: string
  modelValue: Nullable<string>
  options: { value: string; label: string }[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const { t } = useI18n()
</script>
<style lang="sass" scoped>
.radio-button
  display: flex
  justify-content: space-between
  gap: 1.5rem
  flex-wrap: wrap

  @media only screen and (max-width: 768px)
    justify-content: center

label
  display: block
  position: relative
  padding-left: 1.5rem
  cursor: pointer
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  input
    position: absolute
    opacity: 0
    cursor: pointer

  .checkmark
    position: absolute
    top: 0
    left: 0
    height: 16px
    width: 16px
    background-color: white
    border: 1px solid #0B0B0B
    border-radius: 50%

  &:hover input:not(.checked) ~ .checkmark
    background-color: #F9F7BF

  .checkmark:after
    content: ""
    position: absolute
    display: none

  input.checked ~ .checkmark:after
    display: block

  .checkmark:after
    top: 3px
    left: 3px
    width: 10px
    height: 10px
    border-radius: 50%
    background: #0B0B0B
</style>
