import { generateUuid, type Uuid } from '@/modules/shared/domain/uuid/uuid'
import type { ApplicationRepository } from '../domain/applicationRepository'

export function createBuilder(applicationRepository: ApplicationRepository) {
  return async (propertyId: string): Promise<Uuid> => {
    const applicationId = generateUuid()
    await applicationRepository.create(applicationId, propertyId)
    return applicationId
  }
}
