<template>
  <div class="backdrop" v-if="opened" @click="opened = false"></div>
  <div class="profile-dropdown" @click="opened = !opened">
    <div class="main">
      <Avatar :user="user"></Avatar>
      <p class="name">{{ user.name }}</p>
      <img v-if="opened" src="@/assets/images/icons/chevron_up.svg" />
      <img v-else src="@/assets/images/icons/chevron_down.svg" />
    </div>
    <div class="links" v-if="opened">
      <RouterLink
        v-for="section in sections"
        class="section"
        :class="{ selected: router.currentRoute.value.name === section.name }"
        :to="{ name: section.name }"
      >
        {{ t(`profile.${section.key}.title`) }}
      </RouterLink>
      <a @click="onLogout" class="section flex align-center gap-05">
        <img src="@/assets/images/icons/logout.svg" />
        {{ t('auth.logOut') }}
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Avatar } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { authService } from '@/modules/auth'
import type { User } from '@/modules/user/domain/user'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

defineProps<{ user: User }>()

const { t } = useI18n()
const { clear: clearAnalytics } = useAnalytics()

const sections = [
  { key: 'settings', name: 'profile' },
  { key: 'password', name: 'password' },
  { key: 'help', name: 'help' }
]

const opened = ref(false)

async function onLogout() {
  try {
    await authService.signOut()
    clearAnalytics()
    router.push({ name: 'signIn' })
  } catch (error) {
    location.reload()
  }
}
</script>
<style scoped lang="sass">
@import "@/assets/styles/responsive.sass"

.backdrop
  width: 100%
  height: calc(100% - 3.5rem)
  background-color: black
  opacity: 0.76
  position: absolute
  top: 3.5rem
  left: 0
  z-index: 1
  display: none

  @media only screen and (max-width: 768px)
    display: block

.profile-dropdown
  position: relative
  z-index: 2

  .main
    display: flex
    align-items: center
    gap: 1rem
    cursor: pointer

    &:hover
      p
        text-shadow: 0.25px 0 0 black
      img
        transform: scale(1.1)
      :deep(.avatar .letter)
        background-color: #153852

    .name
      display: none

      @include md
        display: block

  .links
    position: absolute
    background-color: white
    padding: 1rem
    border-radius: 1rem
    display: flex
    flex-direction: column
    gap: 0.25rem
    right: 0rem
    top: 3.5rem
    border: 1px solid #F0F0F0
    box-shadow: 0px 1px 4px 0px #0B0B0B0D

    .section
      white-space: nowrap
      text-decoration: none
      padding: 0.5rem 0.75rem
      border-radius: 2rem

      &:not(.selected):hover
        text-decoration: none
        background-color: #F9F7BF

      &.selected
        cursor: default
        background-color: #F6F3A0


    @media only screen and (max-width: 768px)
      position: fixed
      top: 3.5rem
      left: 0
      border-radius: 0
</style>
