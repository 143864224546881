import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { object, ref, string, ValidationError } from 'yup'
import type { UpdatePasswordForm } from '../domain/updatePasswordForm'

const updatePasswordSchema = object<UpdatePasswordForm>({
  password: string().required('validationError.requiredPassword').min(6, 'validationError.formatPassword'),
  passwordConfirmation: string()
    .required('validationError.requiredPassword')
    .min(6, 'validationError.formatPassword')
    .oneOf([ref('password')], 'validationError.passwordsDoNotMatch')
})

export function yupUpdatePasswordFormValidatorBuilder(): Validator<UpdatePasswordForm> {
  return (primitive: Primitive<UpdatePasswordForm>) => {
    try {
      return updatePasswordSchema.validateSync(primitive, { abortEarly: false }) as UpdatePasswordForm
    } catch (e) {
      const error = e as ValidationError
      const errors: Record<string, string> = {}
      error.inner.forEach(({ path, message }) => (errors[path as string] = message))
      throw new FormValidationError(errors)
    }
  }
}
