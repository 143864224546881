import type { Storage } from '../../domain/storage/storage'

export function dateStorageBuilder(key: string): Storage<Date> {
  return {
    get() {
      const isoDate = localStorage.getItem(key)
      return isoDate ? new Date(isoDate) : null
    },
    set(date: Date) {
      localStorage.setItem(key, date.toISOString())
    }
  }
}
