<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <Title class="mb-1" path="property.documents.title" />
        <LandlordDocumentsForm class="mb-1" v-if="landlord" v-model="landlord.documents" />
        <PropertyDocumentsForm v-model="documents" :id="id" :property="property" />
      </div>
    </div>
  </div>
  <Teleport to="#userLayoutFooter" v-if="landlord && property && documents">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter
      v-if="!showOnboardingCompletedModal"
      :loading="loading"
      :landlord="landlord"
      :property="{ ...property, documents }"
      @continue="onContinue"
      @finish="onFinish"
      @exit="onExit"
    />
  </Teleport>
</template>
<script lang="ts" setup>
import {
  LandlordDocumentsForm,
  OnboardingCompletedModal,
  OnboardingFooter,
  PropertyDocumentsForm,
  PropertyStepper,
  Title
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord, updateCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById, finishPropertyOnboarding, updatePropertyDocuments } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyDocuments } from '@/modules/property/domain/propertyDocuments'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const documents = ref<PropertyDocuments | undefined>()
const loading = ref(false)
const showOnboardingCompletedModal = ref(false)

track(AnalyticsEvent.LandlordStartDocumentation)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  documents.value = { ...property.value.documents }
})

async function save() {
  if (!documents.value || !landlord.value) return

  loading.value = true
  try {
    await updateCurrentLandlord(landlord.value)
    await updatePropertyDocuments(id, documents.value)
    track(AnalyticsEvent.LandlordSubmitDocumentation)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'paymentInformation' })
}

async function onFinish() {
  await save()
  await finishPropertyOnboarding(id)
  track(AnalyticsEvent.LandlordOnboardingCompleted)
  showOnboardingCompletedModal.value = true
}
</script>
