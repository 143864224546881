import { authService } from '@/modules/auth'
import {
  sendPasswordRecoveryEmailFormValidator,
  signInFormValidator,
  signUpFormValidator,
  updatePasswordFormValidator,
  updateUserFormValidator,
  userRepository
} from '../infrastructure'
import { deleteCurrentBuilder } from './deleteCurrent'
import { findCurrentBuilder } from './findCurrent'
import { resetPasswordBuilder } from './resetPassword'
import { sendPasswordRecoveryEmailBuilder } from './sendPasswordRecoveryEmail'
import { signInBuilder } from './signIn'
import { signInWithFacebookBuilder } from './signInWithFacebook'
import { signInWithGoogleBuilder } from './signInWithGoogle'
import { signUpBuilder } from './signUp'
import { signUpWithFacebookBuilder } from './signUpWithFacebook'
import { signUpWithGoogleBuilder } from './signUpWithGoogle'
import { updateCurrentBuilder } from './updateCurrent'
import { updateCurrentPasswordBuilder } from './updateCurrentPassword'

export const deleteCurrentUser = deleteCurrentBuilder(userRepository, authService)
export const findCurrentUser = findCurrentBuilder(userRepository, authService)
export const updateCurrentPassword = updateCurrentPasswordBuilder(authService, updatePasswordFormValidator)
export const updateCurrentUser = updateCurrentBuilder(userRepository, authService, updateUserFormValidator)
export const resetPassword = resetPasswordBuilder(userRepository, updatePasswordFormValidator)
export const sendPasswordRecoveryEmail = sendPasswordRecoveryEmailBuilder(
  userRepository,
  sendPasswordRecoveryEmailFormValidator
)
export const signIn = signInBuilder(authService, signInFormValidator)
export const signInWithFacebook = signInWithFacebookBuilder(userRepository, authService)
export const signInWithGoogle = signInWithGoogleBuilder(userRepository, authService)
export const signUp = signUpBuilder(userRepository, authService, signUpFormValidator)
export const signUpWithFacebook = signUpWithFacebookBuilder(userRepository, authService)
export const signUpWithGoogle = signUpWithGoogleBuilder(userRepository, authService)
