<template>
  <div class="flex column gap-2" v-if="model">
    <Title path="property.basic.title" />
    <div data-test-id="propertyTypeSelector">
      <p class="mb-05">{{ t('property.basic.type') }}</p>
      <ChipSelector
        v-model="model.propertyType"
        :options="typeOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectPropertyType"
      />
    </div>
    <div data-test-id="propertyStateSelector">
      <p class="mb-05">{{ t('property.basic.state') }}</p>
      <ChipSelector
        v-model="model.propertyCondition"
        :options="conditionOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectPropertyState"
      />
    </div>
    <div>
      <div class="mb-05 flex align-center gap-05">
        <p>{{ t('property.basic.rooms') }}</p>
        <Tooltip>
          <template #handle>
            <img src="@/assets/images/icons/info_circle.svg" />
          </template>
          <template #content>
            <div class="room-specs">
              <p>
                <b>{{ t('property.basic.roomInformation.bedroom') }}</b>
              </p>
              <p>{{ t('property.basic.roomInformation.bedroomDescription') }}</p>
              <p class="mt-1">
                <b>{{ t('property.basic.roomInformation.bathroom') }}</b>
              </p>
              <p>{{ t('property.basic.roomInformation.bathroomDescription') }}</p>
            </div>
          </template>
        </Tooltip>
      </div>
      <div class="flex wrap gap-1">
        <div data-test-id="bedroomsSelector">
          <p class="s">{{ t('common.property.bedrooms') }}</p>
          <NumberPicker
            v-model="model.bedrooms"
            :minNumber="1"
            :trackEnterEvent="AnalyticsEvent.LandlordPropertyRegisterEnterBedrooms"
          />
        </div>
        <div data-test-id="bathroomsSelector">
          <p class="s">{{ t('common.property.bathrooms') }}</p>
          <NumberPicker
            v-model="model.bathrooms"
            :minNumber="1"
            :trackEnterEvent="AnalyticsEvent.LandlordPropertyRegisterEnterBathrooms"
          />
        </div>
      </div>
    </div>
    <div data-test-id="petsSelector">
      <p class="mb-05">{{ t('property.basic.pets') }}</p>
      <ParagraphSelector
        v-model="model.pets"
        :options="petsOptions"
        :trackSelectEvent="AnalyticsEvent.LandlordPropertyRegisterSelectPets"
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import attachedHouseIcon from '@/assets/images/icons/property_type_attached_house.svg'
import detachedHouseIcon from '@/assets/images/icons/property_type_detached_house.svg'
import duplexIcon from '@/assets/images/icons/property_type_duplex.svg'
import flatIcon from '@/assets/images/icons/property_type_flat.svg'
import penthouseIcon from '@/assets/images/icons/property_type_penthouse.svg'
import { ChipSelector, FormSkeleton, NumberPicker, ParagraphSelector, Title, Tooltip } from '@/components'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { PropertyCondition, type PropertyBasicInformation } from '@/modules/property/domain/propertyBasicInformation'
import { useI18n } from 'vue-i18n'

const model = defineModel<PropertyBasicInformation>()

const { t } = useI18n()

const typeOptions = [
  { label: 'common.property.type.flat', value: 'flat', image: flatIcon },
  { label: 'common.property.type.penthouse', value: 'penthouse', image: penthouseIcon },
  { label: 'common.property.type.duplex', value: 'duplex', image: duplexIcon },
  { label: 'common.property.type.attachedHouse', value: 'attachedSemiDetached', image: attachedHouseIcon },
  { label: 'common.property.type.detachedHouse', value: 'detachedHouse', image: detachedHouseIcon }
]

const conditionOptions = Object.values(PropertyCondition).map((value) => ({
  value,
  label: `common.property.state.${value}`
}))

const petsOptions = [
  { value: true, label: 'property.basic.petsAllowed' },
  { value: false, label: 'property.basic.petsNotAllowed' }
]
</script>
<style lang="sass" scoped>
.room-specs
  min-width: 17.25rem
</style>
