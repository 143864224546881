import type { PropertyDocuments } from '../domain/propertyDocuments'
import type { PropertyRepository } from '../domain/propertyRepository'

export function updateDocumentsBuilder(
  propertyRepository: PropertyRepository,
  validator: Validator<PropertyDocuments>
) {
  return async (id: string, primitive: Primitive<PropertyDocuments>) => {
    const documents = validator(primitive)
    await propertyRepository.updateDocuments(id, documents)
  }
}
