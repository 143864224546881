<template>
  <div class="wrapper">
    <label>{{ label }}</label>
    <select class="select" :value="modelValue" @change="onChange" :class="{ empty: !modelValue }">
      <option value="" disabled>{{ placeholder }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value">
        {{ t(option.label) }}
      </option>
    </select>
    <div class="error-message" v-if="error">{{ t(error) }}</div>
  </div>
</template>
<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import type { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  label: string
  modelValue: Nullable<string>
  options: { value: string; label: string }[]
  error?: string
  placeholder?: string
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const { t } = useI18n()
const { track } = useAnalytics()

function onChange(event: Event) {
  const target = event.target as HTMLSelectElement
  emit('update:modelValue', target.value)
  if (props.trackEnterEvent) {
    track(props.trackEnterEvent)
  }
}
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .select {
    line-height: 1.1rem;
    padding: 0.75rem 0.5rem;
    border: 1px solid $lightBorder;
    border-radius: 6px;
    font-size: 1rem;
    flex-shrink: 0;

    appearance: none;
    background-image: url('@/assets/images/icons/select_dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;

    &.empty {
      color: $grey300;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }
}
</style>
