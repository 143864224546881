<template>
  <ExistingTenantModal
    v-if="foundTenant"
    :email="foundTenant.email"
    @confirm="applyFoundTenant"
    @close="discardFoundTenant"
  />
  <InputField
    v-model="model.email"
    :disabled="isMainTenant || isExistingCoTenant"
    :error="errors['email']"
    :label="`* ${t('forms.email')}`"
    :placeholder="t('forms.email')"
    type="email"
    @blur="onEmailBlur"
    data-test-id="emailInput"
  />
  <InputField
    v-model="model.name"
    :disabled="isExistingCoTenant"
    :error="errors['name']"
    :label="`* ${t('forms.name')}`"
    :placeholder="t('forms.name')"
    type="text"
    data-test-id="nameInput"
  />
  <template v-if="!collapsed">
    <InputField
      v-model="model.surname"
      :disabled="isExistingCoTenant"
      :error="errors['surname']"
      :label="`* ${t('forms.surname')}`"
      :placeholder="t('forms.surname')"
      type="text"
      data-test-id="surnameInput"
    />
    <PhoneInputField
      v-if="!isExistingCoTenant"
      v-model="model.phone"
      :error="errors['phone']"
      :label="`* ${t('forms.phone')}`"
      :placeholder="t('forms.phone')"
      data-test-id="phoneInput"
    />
    <InputField
      v-else
      v-model="model.phone"
      :disabled="true"
      :error="errors['phone']"
      :label="`* ${t('forms.phone')}`"
      :placeholder="t('forms.phone')"
      data-test-id="phoneInput"
    />
    <p class="title">* {{ t('tenant.employmentStatus') }}</p>
    <RadioButton
      v-model="model.employmentStatus"
      :options="employmentStatusOptions"
      name="employmentStatus"
      data-test-id="employmentStatusSelector"
    />
  </template>
</template>
<script lang="ts" setup>
import { ExistingTenantModal, InputField, PhoneInputField, RadioButton } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findTenantByEmail } from '@/modules/tenant/application'
import type { Tenant } from '@/modules/tenant/domain/tenant'
import { TenantEmploymentStatus } from '@/modules/tenant/domain/tenantEmploymentStatus'
import { findCurrentUser } from '@/modules/user/application'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })

const props = defineProps<{ collapsed: boolean; errors: Record<string, string>; isMainTenant: boolean }>()

const { t } = useI18n()
const { track } = useAnalytics()

let userEmail: string
const foundTenant = ref<Tenant | null>(null)
const isExistingCoTenant = computed(() => !props.isMainTenant && model.value.id !== null)

const employmentStatusOptions = Object.values(TenantEmploymentStatus).map((value) => ({
  value,
  label: `tenant.employmentStatuses.${value}`
}))

onMounted(async () => {
  const user = await findCurrentUser()
  userEmail = user.email
})

async function onEmailBlur() {
  if (model.value.email === userEmail) {
    model.value.email = ''
  } else if (model.value.email) {
    foundTenant.value = await findTenantByEmail(model.value.email)
  }
}

function applyFoundTenant() {
  if (foundTenant.value) {
    track(AnalyticsEvent.TenantApplicationUseExistingCotenantInformation)
    model.value = foundTenant.value
    foundTenant.value = null
  }
}

function discardFoundTenant() {
  track(AnalyticsEvent.TenantApplicationDontUseExistingCotenantInformation)
  foundTenant.value = null
  model.value.email = ''
}
</script>
