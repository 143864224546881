import type { ApiError } from '@/modules/shared/domain/errors/apiError'

export interface ApiErrorResponse {
  errors?: ApiError[]
  result?: ApiError
}

export default class HttpError extends Error {
  #msg
  #code
  #status
  #error

  constructor(apiErrorResponse: ApiErrorResponse | ApiError, status: number) {
    function isComplexResponse(response: ApiErrorResponse | ApiError): response is ApiErrorResponse {
      return (response as ApiError).code === undefined
    }

    const error = isComplexResponse(apiErrorResponse)
      ? (apiErrorResponse.errors?.[0] ?? apiErrorResponse.result)
      : apiErrorResponse

    const msg = error?.msg ?? error?.detail ?? error?.message ?? 'unexpected error'
    super(msg)
    this.#msg = msg
    this.#code = isNaN(parseInt(error?.code ?? '0', 10)) ? (error?.code ?? 0) : parseInt(error?.code ?? '0', 10)
    this.#status = error?.status ?? status
    this.#error = error
  }

  get msg() {
    return this.#msg
  }
  get code() {
    return this.#code
  }
  get status() {
    return this.#status
  }
  get error() {
    return this.#error
  }
}
