<template>
  <Modal mandatory>
    <div data-test-id="existingTenantModal">
      <div class="flex align-center justify-center column gap-1 p-5 primary">
        <img src="@/assets/images/congratulations.png" />
        <h2>{{ t('tenant.existingTenant.title') }}</h2>
        <p class="mt-2">{{ t('tenant.existingTenant.description', { email }) }}</p>
        <p>{{ t('tenant.existingTenant.question') }}</p>
      </div>
      <div class="flex align-center justify-center column gap-1 p-3">
        <Button @click="emit('confirm')">{{ t('tenant.existingTenant.confirm') }}</Button>
        <Button @click="emit('close')" variant="secondary">{{ t('tenant.existingTenant.cancel') }}</Button>
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { useI18n } from 'vue-i18n'

defineProps<{ email: Nullable<string> }>()
const emit = defineEmits(['confirm', 'close'])

const { t } = useI18n()
</script>
<style lang="sass" scoped>
button
  width: 16rem
</style>
