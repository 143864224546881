<template>
  <div class="container">
    <div class="flex gap-05 align-center mb-3">
      <RouterLink :to="{ name: 'properties' }">
        <IconButton :icon="arrowBackIcon" />
      </RouterLink>
      <h1>{{ t('property.details.title') }}</h1>
    </div>
    <p class="title mb-2">{{ t('property.details.description') }}</p>
    <div v-if="property">
      <div class="card mb-2">
        <h2 class="mb-1">{{ t('property.details.propertyData.title') }}</h2>
        <div class="flex wrap gap-1">
          <div class="field" :id="field" v-for="field in coreInformationFields">
            <p class="label">{{ t(`property.details.propertyData.${field}`) }}</p>
            <p class="value">{{ coreInformationFormatter(field) }} {{ fieldUnits[field] }}</p>
          </div>
          <div class="field" id="plan">
            <p class="label">{{ t('property.details.propertyData.plan') }}</p>
            <p class="value">
              {{ t(`common.property.pricingPlan.${property.paymentInformation.plan}`) }}
            </p>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <h2 class="mb-1">{{ t('property.details.characteristics.title') }}</h2>
        <p class="title mb-05">{{ t('property.details.characteristics.generalData') }}</p>
        <div class="flex wrap gap-1">
          <div class="field" :id="field" v-for="field in basicInformationFields">
            <p class="label">{{ t(`property.details.characteristics.${field}`) }}</p>
            <p class="value">{{ basicInformationFormatter(field) }}</p>
          </div>
        </div>
        <p class="title mt-2 mb-05">{{ t('property.details.characteristics.equipment') }}</p>
        <div class="flex wrap gap-1">
          <div class="field" :id="field" v-for="field in equipmentInformationFields">
            <p class="label">{{ t(`property.details.characteristics.${field}`) }}</p>
            <p class="value">{{ equipmentInformationFormatter(field) }}</p>
          </div>
        </div>
      </div>
      <div class="card mb-2">
        <h2 class="mb-1">{{ t('property.details.images.title') }}</h2>
        <div class="images flex wrap gap-1">
          <template v-for="image in property.images.files">
            <img v-if="image.uri" :src="image.uri" :alt="image.name" class="mb-1" />
          </template>
        </div>
        <div class="field" id="needsPhotoService" v-if="property.images.needsPhotoService">
          <p class="value">{{ t('property.details.images.needsPhotoService') }}</p>
        </div>
      </div>
      <div class="card">
        <h2>{{ t('property.details.documents.title') }}</h2>
        <div class="mt-1" v-if="landlord">
          <p class="label">{{ t('landlord.documents.type.id') }}</p>
          <div class="flex wrap gap-1">
            <div class="field document" id="id" v-for="document in landlord.documents.id">
              <p class="value"><img src="@/assets/images/icons/document_light.svg" />{{ document.name }}</p>
            </div>
          </div>
        </div>
        <div v-for="field in documentationFields" class="documents">
          <p class="label">{{ t(`property.documents.type.${field}`) }}</p>
          <div class="flex wrap gap-1">
            <div class="field document" :id="field" v-for="document in property.documents[field]">
              <p class="value"><img src="@/assets/images/icons/document_light.svg" />{{ document.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormSkeleton v-else />
  </div>
</template>
<script lang="ts" setup>
import arrowBackIcon from '@/assets/images/icons/arrow_back.svg'
import { FormSkeleton, IconButton } from '@/components'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById } from '@/modules/property/application'
import { requiredPropertyDocumentTypes, type Property } from '@/modules/property/domain/property'
import type { PropertyBasicInformation } from '@/modules/property/domain/propertyBasicInformation'
import { AvailabilityType, type PropertyCoreInformation } from '@/modules/property/domain/propertyCoreInformation'
import type { PropertyEquipmentInformation } from '@/modules/property/domain/propertyEquipmentInformation'
import { router } from '@/router'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const fieldUnits: Record<string, string> = { builtSize: 'm²', rent: '€' }
const basicInformationFields: (keyof PropertyBasicInformation)[] = [
  'propertyType',
  'propertyCondition',
  'bedrooms',
  'bathrooms',
  'pets'
]
const coreInformationFields: (keyof PropertyCoreInformation)[] = [
  'street',
  'floor',
  'catastralReference',
  'builtSize',
  'rent',
  'availabilityType'
]
const equipmentInformationFields: (keyof PropertyEquipmentInformation)[] = [
  'furniture',
  'indoorExtras',
  'outdoorExtras'
]
const documentationFields = computed(() => (property.value ? requiredPropertyDocumentTypes(property.value) : []))

function basicInformationFormatter(field: keyof PropertyBasicInformation) {
  if (!property.value) return '-'

  if (field === 'propertyType') {
    return t(`common.property.type.${property.value?.basicInformation.propertyType}`)
  } else if (field === 'propertyCondition') {
    return t(`common.property.state.${property.value?.basicInformation.propertyCondition}`)
  } else if (field === 'pets') {
    return t(`property.details.characteristics.pets${property.value?.basicInformation.pets ? 'Yes' : 'No'}`)
  }
  return property.value.basicInformation[field]?.toString() ?? '-'
}

function coreInformationFormatter(field: keyof PropertyCoreInformation) {
  if (!property.value) return '-'

  if (field === 'availabilityType') {
    return property.value.coreInformation.availabilityType === AvailabilityType.ExactDate
      ? (property.value.coreInformation.availabilityDate ?? '-')
      : t(`common.property.availability.${property.value.coreInformation.availabilityType}`)
  }
  return property.value.coreInformation[field]?.toString() ?? '-'
}

function equipmentInformationFormatter(field: keyof PropertyEquipmentInformation) {
  if (!property.value) return '-'

  if (field === 'furniture') {
    return t(`property.details.characteristics.furniture${property.value?.basicInformation.pets ? 'Yes' : 'No'}`)
  } else {
    return property.value.equipmentInformation[field].length > 0
      ? property.value.equipmentInformation[field].map((extra) => t(`common.property.${field}.${extra}`)).join(', ')
      : '-'
  }
}

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
})
</script>
<style scoped lang="sass">
.field
  width: 100%

  &#builtSize, &#rent, &#bedrooms, &#bathrooms
    width: calc(50% - 0.5rem)

  @media only screen and (min-width: 768px)
    width: calc(25% - 0.75rem)

    &#builtSize, &#rent, &#bedrooms, &#bathrooms
      width: calc(25% - 0.75rem)

    &#document
      width: calc(33% - 0.66rem)

    &#catastralReference, &#availabilityType, &#plan, &#propertyType, &#propertyCondition, &#pets
      width: calc(50% - 0.5rem)

    &#street, &#indoorExtras
      width: calc(75% - 0.25rem)

    &#outdoorExtras, &#needsPhotoService
      width: 100%

.value
  background-color: #F9F9F9
  border-radius: 0.5rem
  padding: 1rem
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.images img
  width: 7.5rem
  height: 7.5rem
  border-radius: 0.5rem
  object-fit: cover

.documents
  margin-top: 1rem
  padding-top: 1rem
  border-top: 1px solid #E6E6E6

.document .value
  position: relative
  padding-left: 2.5rem

  img
    position: absolute
    left: 0.75rem
    top: 0.8rem
</style>
