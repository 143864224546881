import { object, string, ValidationError } from 'yup'
import type { SignInForm } from '../domain/signInForm'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'

const signInSchema = object<SignInForm>({
  password: string().required('validationError.requiredPassword'),
  email: string()
    .test(
      'is-valid-email',
      'validationError.formatEmail',
      (value) => !value || /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/.test(value)
    )
    .required('validationError.requiredEmail')
})

export function yupSignInFormValidatorBuilder(): Validator<SignInForm> {
  return (primitive: Primitive<SignInForm>) => {
    try {
      return signInSchema.validateSync(primitive, { abortEarly: false }) as SignInForm
    } catch (e) {
      const error = e as ValidationError
      const errors: Record<string, string> = {}
      error.inner.forEach(({ path, message }) => (errors[path as string] = message))
      throw new FormValidationError(errors)
    }
  }
}
