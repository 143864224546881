import type { AuthService } from '@/modules/auth/domain/authService'
import type { UserRepository } from '../domain/userRepository'

export function signInWithGoogleBuilder(userRepository: UserRepository, authService: AuthService) {
  return async () => {
    await authService.signInWithGoogle()
    try {
      const userId = await authService.getUserId()
      await userRepository.get(userId)
    } catch (error) {
      await authService.signOut()
      throw error
    }
  }
}
