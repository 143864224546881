import axios from 'axios'
import requestInterceptor from './axiosRequestInterceptor'
import responseInterceptor from './axiosResponseInterceptor'

export const apiService = axios.create({
  baseURL: import.meta.env.VITE_API_URL
})

export const apiV2Service = axios.create({
  baseURL: import.meta.env.VITE_API_V2_URL + '/api'
})

requestInterceptor(apiService)
responseInterceptor(apiService)
requestInterceptor(apiV2Service)
responseInterceptor(apiV2Service)
