<template>
  <div class="hint" :class="{ mobile }">
    <div>
      <div class="flex justify-between align-center" @click="opened = !opened">
        <p class="title">{{ t('application.requiredDocumentation') }}</p>
        <template v-if="mobile">
          <img v-if="opened" src="@/assets/images/icons/chevron_up.svg" />
          <img v-else src="@/assets/images/icons/chevron_down.svg" />
        </template>
      </div>
      <template v-if="!mobile || opened">
        <template v-for="employmentStatus in employmentStatuses">
          <p class="mt-1">
            <b>{{ t(`tenant.employmentStatuses.${employmentStatus}`) }}</b>
          </p>
          <p class="document-type" v-for="documentType in employmentStatusDocumentTypes[employmentStatus]">
            {{ t(`tenant.documents.${documentType}`) }}
          </p>
        </template>
      </template>
    </div>
    <img v-if="!mobile || opened" class="ellipse" src="@/assets/images/ellipsePink300.png" />
  </div>
</template>
<script lang="ts" setup>
import { employmentStatusDocumentTypes } from '@/modules/tenant/domain/tenantDocuments'
import { TenantEmploymentStatus } from '@/modules/tenant/domain/tenantEmploymentStatus'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

defineProps<{ mobile: boolean }>()

const { t } = useI18n()

const opened = ref(false)
const employmentStatuses = Object.values(TenantEmploymentStatus)
</script>
<style lang="sass" scoped>
.hint
  background-color: #EDB7F7
  border-radius: 1rem
  padding: 1rem
  position: relative
  max-width: 18rem

  &.mobile
    @media only screen and (min-width: 768px)
      display: none

  &:not(.mobile)
    @media only screen and (max-width: 767px)
      display: none

  .ellipse
    position: absolute
    right: 1rem
    top: 50%
    transform: translateY(-50%)

  > div
    position: relative
    z-index: 1

    .title
      font-size: 1rem
      font-weight: bold


    .document-type
      margin: 0
      padding-right: 3rem
</style>
