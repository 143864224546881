<template>
  <div class="flex column gap-1">
    <Button variant="secondary" :icon="googleIcon" @click="onSignInWithGoogle">{{ t('auth.signInWithGoogle') }}</Button>
    <p v-if="errorMessage" class="error-message center">{{ t(errorMessage) }}</p>
  </div>
</template>
<script lang="ts" setup>
import googleIcon from '@/assets/images/g_logo.svg'
import { Button } from '@/components'
import { signInWithGoogle } from '@/modules/user/application'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  navigateOnSuccess: string
}>()

const { t } = useI18n()

const errorMessage = ref('')

async function onSignInWithGoogle() {
  errorMessage.value = ''

  try {
    await signInWithGoogle()
    router.push({ name: props.navigateOnSuccess })
  } catch (error) {
    errorMessage.value = 'genericError.unexpected'
  }
}
</script>
