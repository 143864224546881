<template>
  <div class="basic-layout">
    <nav class="navbar">
      <RouterLink :to="{ name: 'home' }">
        <img src="@/assets/images/vivara_logo_dark.svg" />
      </RouterLink>
      <LanguageSelector />
    </nav>
    <div class="content"><slot /></div>
    <div class="footer">
      <p class="light">{{ t('infoFooter.info1') }}</p>
      <p class="light">{{ t('infoFooter.info2') }}</p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { LanguageSelector } from '@/components'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<style lang="sass" scoped>
@import "@/assets/styles/responsive.sass"

$navbar-height: 3.5rem
$footer-height: 3.5rem
$footer-height-mobile: 7rem

.navbar
  height: $navbar-height
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #f0f0f0
  padding: 0rem 1rem
  box-sizing: border-box

.footer
  height: $footer-height-mobile
  display: flex
  flex-direction: column
  align-items: center
  border-top: 1px solid #f0f0f0
  padding: 0.5rem 1rem
  box-sizing: border-box

  p
    font-size: 0.75rem

  @include md
    height: $footer-height
    flex-direction: row
    justify-content: space-between

    p
      font-size: 0.875rem

.content
  min-height: calc(100vh - #{$navbar-height} - #{$footer-height-mobile})

  @include md
    min-height: calc(100vh - #{$navbar-height} - #{$footer-height})
</style>
